import {ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';

const Points = styled.div`
  margin-left: 5px;
`;

type IProps = {
  isLoading: boolean;
};
function LoadingPoints({isLoading}: IProps): ReactElement {
  const [point, setPoint] = useState(0);

  useEffect(() => {
    if (isLoading) {
      const loadingPoint = setInterval(() => {
        setPoint((prevPoint) => (prevPoint + 1) % 4);
      }, 600);

      return () => clearInterval(loadingPoint);
    }
  }, [isLoading]);
  return <Points>{'.'.repeat(point)}</Points>;
}

export default LoadingPoints;
