import React, {memo, ReactElement, useEffect, useReducer, useRef, useState} from 'react';
import {NodeProps, useReactFlow, useViewport} from 'reactflow';
import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import actionReducer from 'components/mpfd/hooks/reducer/action';
import {IPfdState} from 'components/mpfd/type';
import MetaPfdViewer from 'components/pc/widgets/metaPfd/MetaPfdViewer';
import styled from 'styled-components';
import ImageLoadStep from 'components/mpfd/etc/ImageLoadStep';
import {CONST} from 'components/mpfd/hooks/functions/action-calculation-functions';
import MetaPfdWidgetHeader from 'components/pc/widgets/metaPfd/MetaPfdWidgetHeader';
import useMetaPfdWidgetEffect from 'components/pc/widgets/metaPfd/useMetaPfdWidgetEffect';
import {IWidgetNodeData} from 'components/pc/types';
import html2canvas from 'html2canvas';
import {IMPfdData} from 'api/data-types';
import {ResizeDragEvent, ResizeParams} from '@reactflow/node-resizer/dist/esm/types';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {useRafState} from 'react-use';
import {getDefaultMat} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import {applyToPoint, inverse} from 'transformation-matrix';
import useLatestTagHandler, {ILatestTag} from 'hooks/useLatesetTagHandler';
import WidgetLiveUpdateStatus from 'components/pc/widgets/parts/WidgetLiveUpdateStatus';
import WidgetApiCallFailStatus from 'components/pc/widgets/parts/WidgetApiCallFailStatus';
import useLatestNodeHandler from 'hooks/useLatestNodeHandler';

const AlternativeContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 100;
`;

function MetaPfdWidget({data, id, zIndex, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const latestTagHandlerForTable = useLatestTagHandler({type: 'latest_count', latest_count: 1});
  const latestTagHandlerForChart = useLatestTagHandler({type: 'latest_count', latest_count: 10});
  const latestNodeHandlerForTable = useLatestNodeHandler({type: 'latest_count', latest_count: 1});
  const latestNodeHandlerForChart = useLatestNodeHandler({type: 'latest_count', latest_count: 10});
  const [state, dispatchToReducer] = useReducer(actionReducer, CONST.state as IPfdState);
  const [imgHeight, setImgHeight] = useState(0);
  const [imgWidth, setImgWidth] = useState(0);
  const {cfgState, imgLoadStatus} = useMetaPfdWidgetEffect(
    data,
    id,
    dispatchToReducer,
    setImgWidth,
    setImgHeight,
    latestTagHandlerForTable,
    latestTagHandlerForChart
  );
  const [cfg, setCfg] = cfgState;
  const [resizingStatus, setResizingStatus] = useState(false);
  const widgetRef = useRef(null);
  const [snapShotImage, setSnapShotImage] = useState(null);
  const viewport = useViewport();
  const [updateTime, setUpdateTime] = useState(0);
  const [isApiCallFail, setIsApiCallFail] = useState(false);
  const reactFlow = useReactFlow();
  const [title, setTitle] = useState('');

  // useEffect(() => {
  //   let t = 0;
  //   const chartDataKeys = latestTagHandlerForChart?.latestResult
  //     ? Object.keys(latestTagHandlerForChart?.latestResult)
  //     : [];
  //   const tableDataKeys = latestTagHandlerForTable?.latestResult
  //     ? Object.keys(latestTagHandlerForTable?.latestResult)
  //     : [];
  //
  //   const findChartDataKey = chartDataKeys.find((item) => item.includes(id));
  //   const findTableDataKey = tableDataKeys.find((item) => item.includes(id));
  //
  //   if (findChartDataKey) {
  //     const d = latestTagHandlerForChart?.latestResult[findChartDataKey] as ILatestTag[];
  //     const updateTime = d?.[0]?.updateTime;
  //     if (t < updateTime) t = updateTime;
  //   }
  //   if (findTableDataKey) {
  //     const d = latestTagHandlerForTable?.latestResult[findTableDataKey] as ILatestTag[];
  //     const updateTime = d?.[0]?.updateTime;
  //     if (t < updateTime) t = updateTime;
  //   }
  //   if (t > 0) {
  //     setUpdateTime(t);
  //   }
  //
  //   setTitle(data.title);
  //
  //   reactFlow.setNodes((nodes) =>
  //     nodes.map((node) =>
  //       node.id === id
  //         ? {
  //             ...node,
  //             data: {
  //               ...node.data,
  //               metaData: {
  //                 ...node.data.metaData,
  //                 latestDataForTable: latestTagHandlerForTable?.latestResult,
  //                 latestDataForChart: latestTagHandlerForChart?.latestResult
  //               }
  //             }
  //           }
  //         : node
  //     )
  //   );
  // }, [latestTagHandlerForChart?.latestResult, latestTagHandlerForTable?.latestResult]);

  const snapShotCurrentWidgetImage = async () => {
    if (snapShotImage) {
      return;
    }
    const originalWidth = widgetRef.current.style.width;
    const originalHeight = widgetRef.current.style.height;

    const currentWidth = widgetRef.current.offsetWidth;
    const currentHeight = widgetRef.current.offsetHeight;

    widgetRef.current.style.width = `${currentWidth / viewport.zoom}px`;
    widgetRef.current.style.height = `${currentHeight / viewport.zoom}px`;

    const imageLayer = widgetRef.current.querySelectorAll('.image-layer');
    const annotationLayer = widgetRef.current.querySelectorAll('.annotation-layer');

    imageLayer.forEach((layer) => {
      layer.style.width = `${currentWidth / viewport.zoom}px`;
      layer.style.height = `${currentHeight / viewport.zoom}px`;
    });

    annotationLayer.forEach((layer) => {
      layer.style.width = `${currentWidth / viewport.zoom}px`;
      layer.style.height = `${currentHeight / viewport.zoom}px`;
    });

    return html2canvas(widgetRef.current, {removeContainer: true}).then((canvas) => {
      widgetRef.current.style.width = originalWidth;
      widgetRef.current.style.height = originalHeight;
      imageLayer.forEach((layer) => {
        layer.style.width = '100%';
        layer.style.height = '100%';
      });

      annotationLayer.forEach((layer) => {
        layer.style.width = originalWidth;
        layer.style.height = originalHeight;
      });
      // setSnapShotImage(canvas.toDataURL());
      setTimeout(() => setSnapShotImage(canvas.toDataURL()), 0);
    });
  };

  useEffect(() => {
    if (state.images.imageSrc) {
      snapShotCurrentWidgetImage().then();
    }
  }, [state]);

  const onResizingWidgetStart = (event: ResizeDragEvent, params: ResizeParams, bool: boolean) => {
    if (!snapShotImage) {
      snapShotCurrentWidgetImage().then(() => setResizingStatus(bool));
    } else {
      setResizingStatus(bool);
    }
  };

  const onResizingWidgetEnd = (event: ResizeDragEvent, params: ResizeParams, bool: boolean) => {
    const mPfdData = data.mPfdFileData.stateData as IMPfdData;
    const imageRatio = mPfdData.imageInfo.naturalHeight / mPfdData.imageInfo.naturalWidth;
    const w =
      mPfdData?.imageInfo?.naturalWidth / 2 < params.width ? params.width : mPfdData?.imageInfo?.naturalWidth / 2;
    const calculatedHeight = imageRatio * w;
    setImgHeight(calculatedHeight);
    setImgWidth(w);
    setResizingStatus(bool);

    // rf.setNodes((n) => n.map((item) => (item.id === id ? {...item, width: w, height: calculatedHeight} : item)));
  };

  /**
   * MIGRATION-1 :
   * data : {stateData: IMPfdData} :2.12 version
   * data : {mPfdFileData : {stateData: IMPfdData}} : 2.13 version
   */
  let mPfdData: IMPfdData;
  const [mat] = useRafState(getDefaultMat());
  let imagePosition;
  let metaPfdWidgetScale: 1;
  if (data?.mPfdFileData?.stateData) {
    mPfdData = data.mPfdFileData.stateData as IMPfdData;
  } else {
    const maybeHasStateData = data as {stateData?: IMPfdData};
    mPfdData = maybeHasStateData?.stateData;
  }
  if (mPfdData) {
    const fitScale = Math.max(
      mPfdData.imageInfo.naturalWidth / (imgWidth || mPfdData.imageInfo.naturalWidth),
      mPfdData.imageInfo.naturalHeight / (imgHeight || mPfdData.imageInfo.naturalHeight)
    );
    const [iw, ih] = [mPfdData.imageInfo.naturalWidth / fitScale, mPfdData.imageInfo.naturalHeight / fitScale];
    imagePosition = {
      topLeft: applyToPoint(inverse({...mat}), {x: 0, y: 0}),
      bottomRight: applyToPoint(inverse({...mat}), {x: iw, y: ih})
    };
    if (imgWidth > 0) {
      metaPfdWidgetScale = mPfdData.imageInfo.naturalWidth / imgWidth;
    } else {
      metaPfdWidgetScale = 1;
    }
  }

  const onChangeLive = () => {
    setCfg((prev) => ({...prev, isLiveUpdateDataValues: !cfg?.isLiveUpdateDataValues}));
    const metaData = {
      cfg: {
        ...cfg,
        isLiveUpdateDataValues: !cfg?.isLiveUpdateDataValues
      }
    };
    reactFlow.setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id ? {...node, data: {...node.data, metaData: {...node.data.metaData, ...metaData}}} : node
      )
    );
  };

  // console.log(state?.regions?.map((list) => list.type === 'port').map((item) => item.portType === 'From'));
  const port = state.regions.filter((item) => item.type === 'port');
  const portType = port.map((item) => item.portType);

  // console.log(portType);

  return (
    <WidgetContainer
      {...rest}
      id={id}
      data={{...data, zIndex, imgLoadStatus, imagePosition}}
      onResizingWidgetStart={onResizingWidgetStart}
      onResizingWidgetEnd={onResizingWidgetEnd}
      type="MetaPfdWidget"
    >
      <WidgetHeader
        {...rest}
        type="MetaPfdWidget"
        icon={data?.icon}
        id={id}
        title={getWidgetTitle({titleData: title, data})}
        suffix="- Meta PFD"
      >
        {/*<MetaPfdWidgetHeader cfgSt ate={cfgState} id={id} />*/}
      </WidgetHeader>
      <WidgetActionPanel align="right">
        <WidgetLiveUpdateStatus
          id={id}
          updateTime={updateTime}
          isApiCallFail={isApiCallFail}
          isLiveUpdate={cfg?.isLiveUpdateDataValues}
          onChangeLiveUpdate={onChangeLive}
        />
        <WidgetApiCallFailStatus isApiCallFail={isApiCallFail} />
        <MetaPfdWidgetHeader cfgState={cfgState} id={id} />
      </WidgetActionPanel>
      <WidgetBody actionMenuHeight={80}>
        <div
          style={{
            height: !resizingStatus ? (imgHeight ? imgHeight + 'px' : '100%') : '100%',
            width: '100%'
          }}
        >
          {/*{resizingStatus && snapShotImage ? (*/}
          {/*  <>*/}
          {/*    <img style={{width: '100%', height: '100%'}} src={snapShotImage} alt={''} />*/}
          {/*  </>*/}
          {/*) : (*/}
          <>
            {state?.images?.imageSrc ? (
              <>
                {!snapShotImage && (
                  <>
                    <AlternativeContainer>
                      <ImageLoadStep imgLoadStatus={'loading'} />
                    </AlternativeContainer>
                  </>
                )}
                <MetaPfdViewer
                  cfg={cfg}
                  state={state}
                  id={id}
                  dispatchToReducer={dispatchToReducer}
                  layoutChanging={resizingStatus}
                  widgetRef={widgetRef}
                  snapShotImage={snapShotImage}
                  selected={rest.selected}
                  imagePositionTest={imagePosition}
                  metaPfdWidgetScale={metaPfdWidgetScale}
                  latestTagHandlerForTable={latestTagHandlerForTable}
                  latestTagHandlerForChart={latestTagHandlerForChart}
                  latestNodeHandlerForTable={latestNodeHandlerForTable}
                  latestNodeHandlerForChart={latestNodeHandlerForChart}
                />
              </>
            ) : (
              <AlternativeContainer>
                <ImageLoadStep imgLoadStatus={imgLoadStatus} />
              </AlternativeContainer>
            )}
          </>
          {/*)}*/}
        </div>
      </WidgetBody>
    </WidgetContainer>
  );
}

// export default MetaPfdWidget;

export default memo(MetaPfdWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
