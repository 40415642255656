import {ChangeEvent, ReactElement} from 'react';
import styled from 'styled-components';
import {FormRow, TextForm} from 'components/forms';
import {IModelRunnerLoader} from 'components/pc/widgets/modelRunner/useModelRunnerLoader';

const Container = styled.div`
  width: 280px;
`;

type IProps = {
  loader: IModelRunnerLoader;
};
function ServerInfo({loader}: IProps): ReactElement {
  const {server, updateServer} = loader;

  const disabled = !server?.custom;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    switch (name) {
      case 'name': {
        const name = value;
        updateServer({name});
        break;
      }
      case 'ip': {
        const ip = value;
        updateServer({ip});
        break;
      }
      case 'port': {
        const port = Number(value);
        updateServer({port});
        break;
      }
    }

    // fixme:
    /*setModelRunnerInfo((prev) => {
      const updatedServer = {
        ...prev.selectedServer,
        [name]: name === 'port' ? Number(value) : value
      };
      return {...prev, selectedServer: updatedServer};
    });*/
  };

  return (
    <Container>
      <FormRow header="Server Name">
        <TextForm
          disabled={disabled}
          name="name"
          type="text"
          position="right"
          value={server?.name || ''}
          onChange={onChange}
        />
      </FormRow>
      <FormRow header="Address">
        <TextForm
          disabled={disabled}
          name="ip"
          type="text"
          position="right"
          value={server?.ip || ''}
          onChange={onChange}
        />
      </FormRow>
      <FormRow header="Port">
        <TextForm
          disabled={disabled}
          name="port"
          type="text"
          position="right"
          value={server?.port || ''}
          onChange={onChange}
        />
      </FormRow>
    </Container>
  );
}

export default ServerInfo;
