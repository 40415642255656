import {ReactElement, useRef, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFont} from '@fortawesome/pro-solid-svg-icons';
import {EditorState} from 'draft-js';
import styled from 'styled-components';

const Container = styled.div`
  //--sticky-note-bg-color: #fff7d1;
  background-color: #fff7d1;
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 4px;
  //background: var(--sticky-note-bg-color);
  min-width: 100px;
  cursor: default;

  .rdw-editor-toolbar,
  .rdw-option-wrapper {
    //background: var(--sticky-note-bg-color);
    background: #fff7d1;
  }

  .widget-header {
    //background: var(--sticky-note-bg-color);
    background: #fff7d1;
    border-bottom: 1px dashed #e7ddb4;
  }

  .demo-editor {
    max-height: calc(100% - 44px);
    background-color: #fff7d1;
    //background-color: var(--sticky-note-bg-color);

    &.hide-toolbar {
      max-height: calc(100%);
    }

    .DraftEditor-root {
      height: 100%;
    }
  }

  .rdw-editor-toolbar {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    width: 100%;
    padding: 4px;
    height: 36px;
    z-index: 100;

    .toolbar-font-size {
      width: 50px;
      //background-color: var(--sticky-note-bg-color);
      background-color: #fff7d1;
      border: 1px solid #ccc9b2;
    }
  }

  .rdw-editor-wrapper {
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .rdw-option-wrapper {
    border: none;
  }

  .demo-editor {
    padding: 20px 20px 10px 20px;

    &::-webkit-scrollbar {
      width: 10px;
      //background-color: var(--sticky-note-bg-color);
      background-color: #fff7d1;
      //height: calc((100% - 25px));
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(44, 44, 44, 0.2);
    }
  }

  .rdw-image-wrapper {
    position: static;

    .rdw-image-modal {
      width: 300px;
      height: 240px;
      top: calc(-260px - 50%);
      left: calc(-165px + 50%);

      .rdw-image-modal-size {
        display: none;
      }

      .rdw-image-modal-upload-option {
        height: 100px;
      }
    }
  }

  .rdw-image-alignment-options-popup {
    //background: var(--sticky-note-bg-color);
    background: #fff7d1;
    border: none;
  }

  .rdw-dropdown-optionwrapper {
    top: -280px;
  }

  .public-DraftStyleDefault-block {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const toolbar = {
  options: ['fontSize', 'inline', 'list'], //, 'image'
  inline: {
    className: 'toolbar_inline',
    icon: <FontAwesomeIcon icon={faFont} />,
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough']
  },
  fontSize: {
    inDropdown: false,
    className: 'toolbar-font-size nowheel',
    dropdownClassName: 'toolbar-font-size-dropdown nowheel'
  },
  link: {
    inDropdown: true,
    className: 'toolbar_link'
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered']
  },
  image: {
    urlEnabled: false,
    previewImage: true,
    uploadCallback: (file: File) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({
            data: {
              url: reader.result
            }
          });
        };
        reader.onerror = (reason) => reject(reason);
        reader.readAsDataURL(file);
      });
    }
  }
};

type IProps = {
  onEditorStateChange: (newEditorState: EditorState) => void;
  readOnly: boolean;
  editorState: EditorState;
};

function StickyEditor({onEditorStateChange, readOnly, editorState}: IProps): ReactElement {
  const editorRef = useRef(null);
  const [isBlurred, setIsBlurred] = useState(true);

  return (
    <Container>
      <Editor
        ref={editorRef}
        handlePastedText={() => false}
        editorState={editorState}
        editorClassName={readOnly || isBlurred ? 'demo-editor hide-toolbar' : 'demo-editor'}
        onEditorStateChange={onEditorStateChange}
        toolbarHidden={readOnly || isBlurred}
        onFocus={() => setIsBlurred(false)}
        onBlur={() => setIsBlurred(true)}
        toolbar={toolbar}
        readOnly={readOnly}
      />
    </Container>
  );
}

export default StickyEditor;
