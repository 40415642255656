import {ReactElement} from 'react';
import {faLaptopBinary} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 130px;
  margin-bottom: 16px;
  cursor: pointer;
  flex-shrink: 0;
`;

const Title = styled.div`
  font-weight: 500;
  line-height: 1.2em;
  text-align: center;
`;

type IProps = {
  title: string;
  onClick(): void;
};

function ServerItem({title, onClick}: IProps): ReactElement {
  return (
    <Container onClick={onClick}>
      <FontAwesomeIcon icon={faLaptopBinary as IconProp} size="2x" />
      <Title>{title}</Title>
    </Container>
  );
}

export default ServerItem;
