import {
  Context,
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import {DatabaseCategory, DatabaseHierarchy, IApiReturnBasic, IGlobalSetting, ITreeNode} from 'api/data-types';
import {getHierarchy} from 'api/function';
import {AuthContext} from 'components/auth/AuthProvider';
import {LocalStorageManager} from 'utils/local-storage-manager';
import useApi from 'api/useApi';
import {IPythonKernel} from 'components/pc/widgets/pythonEditor/usePythonEnv';

export const treeCategoryList: TreeCategory[] = ['Model', 'Plant', 'Weather', 'Commodity'];
export type TreeCategory = 'Model' | 'Plant' | 'Weather' | 'Commodity';

const defaultGlobalSettings = LocalStorageManager.getItem<IGlobalSetting>('GLOBAL_SETTINGS') || {
  significantDigit: 4,
  panDistance: 10
};

export type IDataContext = {
  modelTree: ITreeNode;
  weatherTree: ITreeNode;
  commodityTree: ITreeNode;
  plantTree: ITreeNode;
  tagList: ITreeNode[];
  modelHierarchy: any;
  commodityJson: any;
  weatherJson: any;
  plantJson: any;
  pythonEnvList: string[];
  pythonKernelList: IPythonKernel[];
  getPythonEnvList(): void;
  getPythonKernelList(): Promise<IPythonKernel[]>;
  getTagList(targetList: TreeCategory[]): Promise<ITreeNode[]>;
  getTree(target: TreeCategory): ITreeNode;
  globalSettingsModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  globalSettingsState: [IGlobalSetting, Dispatch<SetStateAction<IGlobalSetting>>];
  availableDatabaseList: DatabaseCategory[];
  availableDatabaseHierarchyList: DatabaseHierarchy[];
};

export const DataContext: Context<IDataContext> = createContext(null);

function DataProvider({children}: PropsWithChildren) {
  const {userProfile} = useContext(AuthContext);
  const [modelTree, setModelTree] = useState<ITreeNode>();
  const [modelJson, setModelJson] = useState({});
  const [commodityJson, setCommodityJson] = useState({});
  const [weatherJson, setWeatherJson] = useState({});
  const [plantJson, setPlantJson] = useState({});
  const [weatherTree, setWeatherTree] = useState<ITreeNode>();
  const [commodityTree, setCommodityTree] = useState<ITreeNode>();
  const [plantTree, setPlantTree] = useState<ITreeNode>();
  const [tagList, setTagList] = useState<ITreeNode[]>([]);
  const [pythonEnvList, setPythonEnvList] = useState<string[]>([]);
  const [pythonKernelList, setPythonKernelList] = useState<IPythonKernel[]>([]);
  const globalSettingsModalState = useState(false);
  const globalSettingsState = useState(defaultGlobalSettings);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;
  const [availableDatabaseList, setAvailableDatabaseList] = useState<DatabaseCategory[]>([]);
  const [availableDatabaseHierarchyList, setAvailableDatabaseHierarchyList] = useState<DatabaseHierarchy[]>([]);
  const api = useApi();

  const getTagList = async (targetList: TreeCategory[]): Promise<ITreeNode[]> => {
    let list = [];
    for (let i = 0; i < targetList.length; i++) {
      const d = getTag(targetList[i]);
      list.push(d);
    }
    const results = await Promise.all(list);
    setTagList(results);
    return results;
  };

  const getTag = async (target: TreeCategory): Promise<ITreeNode> => {
    const {hierarchy, json} = await getHierarchy(target);
    switch (target) {
      case 'Model':
        setModelTree(hierarchy);
        setModelJson(json);
        return hierarchy;
      case 'Weather':
        setWeatherTree(hierarchy);
        setWeatherJson(json);
        return hierarchy;
      case 'Commodity':
        setCommodityTree(hierarchy);
        setCommodityJson(json);
        return hierarchy;
      case 'Plant':
        setPlantTree(hierarchy);
        setPlantJson(json);
        return hierarchy;
    }
  };

  const getTree = (target: string) => {
    switch (target) {
      case 'Model':
        return modelTree;
      case 'Weather':
        return weatherTree;
      case 'Commodity':
        return commodityTree;
      case 'Plant':
        return plantTree;
    }
  };

  const getAvailableDbList = () => {
    api
      .get<IApiReturnBasic>('/db_manage/get_available_db_list')
      .then((res) => {
        if (res.success) {
          const db_list = (res.data || []) as DatabaseCategory[];
          setAvailableDatabaseList(db_list);
          getAvailableDbHierarchyList(db_list);
        }
      })
      .catch((e) => {
        console.log('getAvailableDbList() error catch>>', e);
        // todo: db 가 copy 되지 않았을 경우 관리자용 해당 기능으로 이동하여 copy 해야 함을 안내 필요
      });
  };

  const getAvailableDbHierarchyList = (db_list: DatabaseCategory[]) => {
    api
      .post<IApiReturnBasic>('/db_manage/get_db_hierarchy', {
        db_list: db_list.map((item) => item.name)
      })
      .then((res) => {
        if (res.success) {
          const db_hierarchy_list = res.data as DatabaseHierarchy[];
          setAvailableDatabaseHierarchyList(db_hierarchy_list);
        }
      });
  };

  const getPythonEnvList = (): void => {
    api.get<IApiReturnBasic>('/python_editor/env_list').then((result) => {
      if (result.success) {
        const envList = (result.data || []) as string[];
        setPythonEnvList(envList);
      }
    });
  };

  const getPythonKernelList = async (): Promise<IPythonKernel[]> => {
    return api.get<IApiReturnBasic>('/python_editor/kernel_list').then((result) => {
      if (result.success) {
        const kernelList = (result.data || []) as IPythonKernel[];
        setPythonKernelList(kernelList);
        return kernelList;
      }
      return [];
    });
  };

  useEffect(() => {
    if (userProfile && token) {
      getTagList(['Model', 'Plant', 'Weather', 'Commodity']);
      getAvailableDbList();
    }
  }, [userProfile, token]);

  const collection = {
    modelTree,
    weatherTree,
    commodityTree,
    tagList,
    plantTree,
    modelHierarchy: modelJson,
    commodityJson,
    weatherJson,
    plantJson,
    pythonEnvList,
    pythonKernelList,
    getPythonEnvList,
    getPythonKernelList,
    getTagList,
    getTree,
    globalSettingsModalState,
    globalSettingsState,
    availableDatabaseList,
    availableDatabaseHierarchyList
  };

  return <DataContext.Provider value={collection}>{children}</DataContext.Provider>;
}

export default DataProvider;
