import {ILatestNode} from 'hooks/useLatestNodeHandler';

/**
 * custom : "localhost:9000 Emulator 를 통해 /model_manager/connect 시도 및 websocket 연결을 할것인지 분기처리 하는 인자
 */
export type IModelRunnerServer = {
  name: string;
  ip: string;
  port: number;
  custom?: boolean;
};

export type IModelRunnerProject = IProjectInfo & {
  ioInfo: IoInfo[];
  isRun: boolean;
  modelInfo: IModelInfo[];
  model_list: [];
  projectName: string;
  alias: string;
  seqInfo: ISeqInfo[];
  isBatch?: boolean;
};

export type IoInfo = {
  Type: 'Import' | 'Export' | string;
  Name: string;
  Buffer: number;
  Data: IoInfoData[];
};

export type IoInfoData = {
  Tag: string;
  Value?: number;
  Unit?: string;
};

export type IModelInfo = {
  Name: string;
  Status: string;
  Type: string;
  Version: string;
};

export type ISeqInfo = {
  Command:
    | 'WAIT_IMPORT'
    | 'RUN_MODEL'
    | 'WAIT_MODELRUN'
    | 'UPDATE_MODELINPUT'
    | 'EXPORT_DATA'
    | 'SAVE_MODEL'
    | 'UPDATE_MODEL'
    | string;
  Name: string;
  Repeat: string;
  Status: 'Idle' | 'Completed' | 'Running';
};

export type IModelRunnerVariable = {
  variableName: string;
  flowType?: 'Import' | 'Export' | string;
  variableValue?: number | string;
  path?: string[];
  variableDbType?: 'local' | 'cloud' | string;
  keys: string;
};

export type INodeSelectorTargetInfo = {
  key: string;
  path: string[];
};

export type IValueGroupObj = {
  [key: string]: ILatestNode;
};

export type IModelRunnerRunConfig = {
  autoRun: boolean;
  batchRun: boolean;
  intervalUnit: number;
  intervalValue: number;
  environment: string;
};

export type IModelRunnerInfo = {
  modelRunnerServerList?: IModelRunnerServer[];
  selectedServer?: IModelRunnerServer;
  // isServerConnected?: boolean;
  // isProjectConnected?: boolean;
  modelRunnerProjectList?: IModelRunnerProject[];
  selectedProject?: IModelRunnerProject;
};

/*******************************************************
 * Receive Message (from MMS)
 * *****************************************************/
export type IReceiveMessage = {
  Type: 'Rsp' | 'Cmd';
  Cmd:
    | 'ReqProjectList'
    | 'SendProjectInput'
    | 'ReqProjectOutput'
    | 'SendProjectAction'
    | 'ReqProjectInfo'
    | 'ExportReady'
    | 'ReqProjectOpen'
    | 'ReqProjectConnect'
    | 'ReqProjectCreate'
    | 'MacroFinished';

  Sender: string;
  Receiver: string;
  Data:
    | IReceiveMessageRequestProjectListData
    | IReceiveMessageSendProjectInputData
    | IReceiveMessageRequestProjectOutputData
    | IReceiveMessageSendProjectActionData
    | IReceiveMessageRequestProjectInfoData
    | IReceiveMessageExportReadyData
    | IReceiveMessageRequestProjectCreateData
    | IReceiveMessageMacroFinishedData;
};
type INotify = {
  Notify: {
    ErrorCode: number;
    Message: string;
  };
};

export type IProjectInfo = {
  server: string;
  projectName: string;
  fileName: string; // todo 보낼 때 project Name 으로 보내는 작업 필요
  owner: string;
  pmvUser: string;
  isPublic: boolean;
  isEditable: boolean;
  isUserProject: boolean;
  isRunning: boolean;
  description: string;
};

// onMessage receive 값으로 받은 project 목록 데이터의 type 정의
export type IReceiveProjectInfo = {
  Server: string;
  ProjectName: string;
  FileName: string;
  Owner: string;
  PMvUser: string;
  IsPublic: boolean;
  IsEditable: boolean;
  IsUserProject: boolean;
  IsRunning: boolean;
  Description: string;
};

export type IOutputList = {
  Data: IoInfoData[];
  DataOATime: number | string;
  Validity: boolean;
};

export type IReceiveMessageRequestProjectListData = INotify & {
  ProjectList: IReceiveProjectInfo[];
};
export type IReceiveMessageSendProjectInputData = INotify & {
  ProjectName: string;
};
export type IReceiveMessageRequestProjectOutputData = INotify & {
  ProjectName: string;
  ExportName: string;
  OutputList: IOutputList;
};
export type IReceiveMessageSendProjectActionData = INotify & {
  ProjectName: string;
  Action: 'RunSequence';
};
export type IReceiveMessageRequestProjectInfoData = INotify & {
  ProjectName: string;
  IOInfo: IoInfo[];
  ModelInfo: IModelInfo[];
  SeqInfo: ISeqInfo[];
};
export type IReceiveMessageExportReadyData = {
  ProjectName: string;
  ExportName: string;
};
export type IReceiveMessageRequestProjectCreateData = INotify & {
  MasterProjectName: string;
  RunMacro: boolean;
  UserProjectName: string;
};
export type IReceiveMessageRequestProjectConnectData = INotify & {
  ProjectName: string;
  PMvUser: string;
};
export type IReceiveMessageRequestProjectOpenData = INotify & {
  ProjectName: string;
  RunMacro: boolean;
};
export type IReceiveMessageMacroFinishedData = INotify & {
  MacroName: string;
  MacroResult: boolean;
  ProjectName: string;
};

/*******************************************************
 * Send Message (to MMS)
 * *****************************************************/
export type ISendMessage = {
  type: 'Cmd';
  cmd:
    | 'SendProjectInput'
    | 'ReqProjectCreate'
    | 'ReqProjectOutput'
    | 'ReqProjectList'
    | 'ReqProjectInfo'
    | 'SendProjectAction'
    | 'ReqProjectOpen'
    | 'ReqProjectConnect'
    | 'ReqProjectTerminate';
  receiver: string;
  // data: ISendMessageData;
  data:
    | ISendMessageProjectInputData
    | ISendMessageProjectInfoData
    | ISendMessageProjectOutputData
    | ISendMessageSeqInfoData
    | ISendMessageStartSeqData
    | {}; // requestProjectList 에서는 이렇게 던져야 함
};

export type ISendMessageProjectInputData = {
  ProjectName: string;
  InputList: (string | IoInfoData)[];
  ImportName: string;
};

export type ISendMessageProjectInfoData = {
  ProjectName: string;
  ModelInfo: boolean;
  SeqInfo: boolean;
  IOInfo: boolean;
};

export type ISendMessageProjectOutputData = {
  ExportName: string;
  OutputList: IoInfoData[];
  ProjectName: string;
};

export type ISendMessageSeqInfoData = {
  ProjectName: string;
  ModelInfo: boolean;
  SeqInfo: boolean;
  IOInfo: boolean;
};

export type ISendMessageStartSeqData = {
  ProjectName: string;
  Action: 'RunSequence';
  Data?: {
    Name: string; // Model Name 으로 추정
    Parameters: number[];
  };
};

export const statusMapping = {
  Idle: 'Initializing',
  WAIT_IMPORT: 'Ready',
  RUN_MODEL: 'Running',
  UPDATE_MODEL: 'Update model',
  WAIT_MODELRUN: 'Wait modelrun',
  UPDATE_MODELINPUT: 'Update modelinput',
  EXPORT_DATA: 'Export data',
  SAVE_MODEL: 'Save model',
  Completed: 'Ready'
};
