import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {IPythonEditorKernelCodRunResult} from 'components/pc/widgets/pythonEditor/types';
import NewAccordion from 'components/common/arrocdion/NewAccordion';
import {IAccordionItem} from 'components/pc/widgets/modelRunner/ModelRunnerResult';

const Container = styled.div`
  width: 100%;
  height: 100%;

  pre {
    font-family: consolas;
    font-size: 14px;
    white-space: pre-line;
  }
`;

export type IUpdateResultStates = {
  installResultsUpdated: boolean;
  printOutUpdated: boolean;
  errorUpdated: boolean;
  outflowResultsUpdated: boolean;
};

type IProps = {
  codRunResultState?: [IPythonEditorKernelCodRunResult, Dispatch<SetStateAction<IPythonEditorKernelCodRunResult>>];
};
function PythonCodeResult({codRunResultState}: IProps) {
  const [codeRunResult] = codRunResultState;
  const [options, setOptions] = useState<IAccordionItem[]>([
    {title: 'Output', value: codeRunResult?.output, isActive: false}
    // {title: 'Error', value: codeRunResult?.error, isActive: false}
  ]);

  useEffect(() => {
    if (!codeRunResult) return;
    setOptions(function (prev) {
      const outputItem = prev.find((item) => item.title === 'Output');
      outputItem.value = codeRunResult.output;
      if (codeRunResult.output) outputItem.updatedAt = new Date();

      // const errorItem = prev.find((item) => item.title === 'Error');
      // errorItem.value = codeRunResult.error;
      // if (codeRunResult.error) errorItem.updatedAt = new Date();

      return [...prev];
    });
  }, [codeRunResult]);

  return (
    <Container>
      <NewAccordion options={options} />
    </Container>
  );
}

export default PythonCodeResult;
