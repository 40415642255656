import {usePlacesWidget} from 'react-google-autocomplete';
import styled from 'styled-components';
import {ISizeType} from 'components/common/types';
import classNames from 'classnames';

const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 0;
  transition: all 0.2s;
  margin-top: 1.2rem;
  flex-shrink: 0;

  &.open {
    height: 50px;

    &.md {
      height: 40px;
      margin-top: 1rem;
    }
    &.sm {
      height: 30px;
      margin-top: 0.8rem;
    }
  }
  input {
    flex: 1;
    font-size: 18px;
    font-weight: 400;
    color: #575660;
    background-color: #eff1f3;
    border-radius: 0.8rem;
    border: none;
    outline: none;
    padding: 16px 20px;
  }
  &.md {
    input {
      height: 40px;
      border-radius: 0.6rem;
      padding: 0 14px;
      font-size: 16px;
    }
  }
  &.sm {
    input {
      height: 30px;
      border-radius: 0.4rem;
      padding: 0 8px;
      font-size: 14px;
    }
  }
`;

type IProps = {
  open: boolean;
  size: ISizeType;
  onSelect(place: object): void;
};

function Autocomplete({onSelect, open, size}: IProps) {
  const {ref} = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY,
    onPlaceSelected: onSelect,
    options: {
      types: ['country', 'administrative_area_level_1', 'locality'],
      fields: ['address_components', 'geometry', 'icon', 'name'],
      componentRestrictions: {country: []}
    },
    language: 'en'
  });

  return (
    <Container className={classNames(size.width, {open})}>
      <input ref={ref} placeholder="Enter city name" />
    </Container>
  );
}

export default Autocomplete;
