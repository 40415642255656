import {useContext, useEffect, useState} from 'react';
import {IDatasheetSubject, IWidgetSubject, LocalDatabaseContext} from 'api/LocalDatabaseProvider';
import {DatabaseHierarchy} from 'api/data-types';
import {convert2DRawArrToDbObject} from 'api/function';

type IReturn = {
  localDatabase: IWidgetSubject<IDatasheetSubject>;
  sourceDatabase?: IWidgetSubject<IDatasheetSubject>;
  dynamicHierarchy: DatabaseHierarchy;
};

function useLocalDatabase(id: string): IReturn {
  const {connectionInfo, contentInfo} = useContext(LocalDatabaseContext);
  const [localDatabase, setLocalDatabase] = useState<IWidgetSubject<IDatasheetSubject>>();
  const [sourceDatabase, setSourceDatabase] = useState<IWidgetSubject<IDatasheetSubject>>();

  useEffect(() => {
    const widgetSubscriptionInfo = connectionInfo?.[id];
    const subjectInfoArray = [];

    if (widgetSubscriptionInfo) {
      widgetSubscriptionInfo?.forEach((info) => {
        // console.log(info);
        // console.log(contentInfo);
        const widgetSubjectInfo = {...contentInfo?.[info.id], ...info};
        subjectInfoArray.push(widgetSubjectInfo);
      });
    }
    if (subjectInfoArray?.[0]) {
      //todo datasheet 연결 순서 수정 필요!!

      const sourceInfo: IWidgetSubject<IDatasheetSubject> = subjectInfoArray?.find(
        (item) => item.connectionType === 'source'
      );
      const targetInfo: IWidgetSubject<IDatasheetSubject> = subjectInfoArray?.find(
        (item) => item.connectionType === 'target'
      );

      // todo: action panel ui, datasheet data 의 readonly 등의 비활성화 조치를 해야 함
      // subjectInfo?.ref?.current?.changeReadOnly?.(false).then();

      // console.log('>>> useLocalDatabase > contentInfo', contentInfo);
      // console.log('>>>> useLocalDatabase > localDatabase', subjectInfo);

      setLocalDatabase((prev) => {
        if (prev?.ref) {
          prev?.ref?.current?.changeReadOnly?.(false).then();
        }
        return sourceInfo;
      });

      setSourceDatabase((prev) => {
        if (prev?.ref) {
          prev?.ref?.current?.changeReadOnly?.(false).then();
        }
        return targetInfo;
      });
    }
  }, [connectionInfo, contentInfo, id]);

  const dynamicDbInfo =
    localDatabase?.type === 'DatasheetLocalDbWidget' &&
    convert2DRawArrToDbObject(localDatabase?.ref?.current?.dbData, localDatabase?.id, localDatabase?.name);
  const dynamicHierarchy = dynamicDbInfo?.hierarchy;

  return {localDatabase, sourceDatabase, dynamicHierarchy};
}

export default useLocalDatabase;
