import {ReactElement} from 'react';
import styled from 'styled-components';
import {faBookOpenCover} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IModelRunnerProject} from 'components/pc/widgets/modelRunner/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  flex-shrink: 0;

  &:hover {
    background-color: ${({theme}) => theme.color.iceBlue};
  }
`;

const Title = styled.div`
  word-break: break-all;
  max-width: 150px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

type IProps = {
  project: IModelRunnerProject;
  className?: string;
  onClick(): void;
};

function TemplateItem({project, className, onClick}: IProps): ReactElement {
  return (
    <Container className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faBookOpenCover} size="2xl" />
      <Title>{project.projectName}</Title>
    </Container>
  );
}

export default TemplateItem;
