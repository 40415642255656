import {memo, ReactElement} from 'react';
import {NodeProps} from 'reactflow';
import {WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {faFileImage} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import {getWidgetTitle} from 'utils/processCanvas-functions';

const Container = styled.figure`
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
function ImageWidget({data, id, ...rest}: NodeProps): ReactElement {
  const onClose = (): void => {
    console.log('onClose Image Widget');
  };

  return (
    <WidgetContainer {...rest} data={data} type="ImageWidget">
      <WidgetHeader
        {...rest}
        type="ImageWidget"
        icon={faFileImage}
        id={id}
        title={getWidgetTitle({data})}
        suffix="- Image"
        onClose={onClose}
      />
      <WidgetBody>
        <Container>
          <img src={data.src} alt="" />
        </Container>
      </WidgetBody>
    </WidgetContainer>
  );
}
export default memo(ImageWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
