import {ReactElement, ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: end;
  gap: 8px;
  padding-top: 10px;
  &.multi {
    padding-bottom: 12px;
  }
`;

type IProps = {
  children: ReactNode;
  height?: number;
  type?: 'default' | 'multi';
};

function ModalFooter({children, height = 30, type = 'default'}: IProps): ReactElement {
  return (
    <Container className={type} style={{height}}>
      {children}
    </Container>
  );
}

export default ModalFooter;
