import {Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import {getApiHost} from 'api/function';
import {AuthContext} from 'components/auth/AuthProvider';
import {
  IModelRunnerProject,
  IModelRunnerRunConfig,
  IModelRunnerServer,
  IModelRunnerVariable,
  IoInfoData,
  IOutputList,
  IProjectInfo,
  IReceiveMessage,
  IReceiveMessageRequestProjectConnectData,
  IReceiveMessageRequestProjectCreateData,
  IReceiveMessageRequestProjectInfoData,
  IReceiveMessageRequestProjectListData,
  IReceiveMessageRequestProjectOpenData,
  IReceiveMessageRequestProjectOutputData,
  IReceiveMessageSendProjectActionData,
  IReceiveMessageSendProjectInputData,
  IReceiveProjectInfo,
  ISendMessage,
  ISeqInfo,
  IValueGroupObj,
  statusMapping
} from 'components/pc/widgets/modelRunner/types';
import {IDatasheetSubject, IWidgetSubject} from 'api/LocalDatabaseProvider';
import useApi from 'api/useApi';
import {IApiReturnBasic, IApiServer} from 'api/data-types';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {IModelRunnerWidgetData} from 'components/pc/types';
import {getUniqueKey} from 'utils/commons';
import {modelRunnerDefaultStateValue} from 'components/pc/widgets/modelRunner/constants';

export const getProjectFileName = (fileName: string) => {
  const arr = fileName?.split('\\');
  return arr?.[arr.length - 2] ?? null;
};

export type IModelRunnerLoader = {
  serverList: IModelRunnerServer[];
  server: IModelRunnerServer;
  projectList: IModelRunnerProject[];
  project: IModelRunnerProject;
  variableTable: IModelRunnerVariable[];
  importList: IModelRunnerVariable[];
  exportList: IModelRunnerVariable[];
  socketResponse: IReceiveMessage;
  logData: string;
  messages: string;
  runConfig: IModelRunnerRunConfig;
  isConnected: boolean;
  isValidProject: boolean;
  statusMessage: 'Idle' | 'Completed' | 'Running';
  seqInfo: ISeqInfo;

  sendMessage({type, cmd, receiver, data}: ISendMessage): void;
  requestProjectOpen(fileName: string): void;
  requestProjectConnect(fileName: string): void;
  requestProjectTerminate(fileName: string): void;
  sendProjectInput(projectName: string, fileName: string, ioName: string): Promise<void>;
  connectToServer(server: IModelRunnerServer): Promise<IApiReturnBasic>;
  disconnectFromServer(): void;
  getServerList(): Promise<IApiReturnBasic>;
  requestProjectList(server: IModelRunnerServer): void;
  requestProjectInfo(projectName: string, hasModelInfo?: boolean, hasSeqInfo?: boolean, hasIoInfo?: boolean): void;
  createProject(fileName: string, projectName: string): void;
  updateServer(partial: Partial<IModelRunnerServer>): void;
  updateProject(partial: Partial<IModelRunnerProject>): void;
  resetServer(): void;
  resetProject(): void;
  setMetaData(metaData: IModelRunnerWidgetData): void;
  setVariableTable: Dispatch<SetStateAction<IModelRunnerVariable[]>>;
  updateRunConfig(partial: Partial<IModelRunnerRunConfig>): void;
  runModel(fileName: string): void;
  status(modelStatus: ISeqInfo, type: 'key' | 'value'): {sts: string; cmd: string};
};

type IProps = {
  id: string;
  focusedRowDataRef: MutableRefObject<{}>;
  sourceDataBaseRef: MutableRefObject<IWidgetSubject<IDatasheetSubject>>;
  isRunState: [boolean, Dispatch<SetStateAction<boolean>>];
  valueGroupObjectState: [IValueGroupObj, Dispatch<SetStateAction<IValueGroupObj>>];
};

const API_HOST = getApiHost();

function convertToOATime(value: number) {
  const date = new Date(value);
  return date.getTime() / (1000 * 60 * 60 * 24) + 25569;
}

function useModelRunnerLoader({
  id,
  focusedRowDataRef,
  sourceDataBaseRef,
  isRunState,
  valueGroupObjectState
}: IProps): IModelRunnerLoader {
  const webSocket = useRef(null);
  const [socketResponse, setSocketResponse] = useState<IReceiveMessage>();
  const {userProfile, token} = useContext(AuthContext);
  const api = useApi();

  const [logData, setLogData] = useState('');
  const [messages, setMessages] = useState('');

  const metaDataRef = useRef<IModelRunnerWidgetData>(null);
  const [serverList, setServerList] = useState<IModelRunnerServer[]>([]);
  const [server, setServer] = useState<IModelRunnerServer>(undefined);
  const [projectList, setProjectList] = useState<IModelRunnerProject[]>([]);
  const [project, setProject] = useState<IModelRunnerProject>(undefined);
  const [variableTable, setVariableTable] = useState<IModelRunnerVariable[]>([]);
  const [importList, setImportList] = useState<IModelRunnerVariable[]>([]);
  const [exportList, setExportList] = useState<IModelRunnerVariable[]>([]);
  const [runConfig, setRunConfig] = useState<IModelRunnerRunConfig>(modelRunnerDefaultStateValue.runConfigState);

  const [isRun, setIsRun] = isRunState;

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isValidProject, setIsValidProject] = useState<boolean>(false);
  const [statusMessage, setStatusMessage] = useState<'Idle' | 'Completed' | 'Running'>(null);

  const [seqInfo, setSeqInfo] = useState<ISeqInfo>(null);

  const senderId = userProfile.username + '-' + id;

  // const seqMappingTable = (key) => {};

  const setMetaData = (metaData: IModelRunnerWidgetData): void => {
    // console.log('useModelRunnerLoader > metaData = ', metaData);
    const {modelRunnerInfo, server, project, importList, exportList, runConfig, variableTable} = metaData;
    if (modelRunnerInfo) {
      const {selectedServer, selectedProject} = modelRunnerInfo ?? {};
      setRunConfig(runConfig || modelRunnerDefaultStateValue.runConfigState);
      updateServer(selectedServer);
      updateProject(selectedProject);
      setVariableTable(variableTable);
      setImportList(variableTable.filter((row) => row.flowType === 'Import'));
      setExportList(variableTable.filter((row) => row.flowType === 'Export'));
    } else {
      setRunConfig(runConfig || modelRunnerDefaultStateValue.runConfigState);
      updateServer(server);
      updateProject(project);
      setVariableTable(variableTable);
      setImportList(importList);
      setExportList(exportList);
    }
    metaDataRef.current = metaData;
  };

  useEffect(() => {
    if (metaDataRef.current) {
      metaDataRef.current.server = server;
    }
  }, [server]);

  useEffect(() => {
    if (metaDataRef.current) {
      metaDataRef.current.project = project;
    }
  }, [project]);

  useEffect(() => {
    if (metaDataRef.current) {
      metaDataRef.current.variableTable = variableTable;
    }
  }, [variableTable]);

  useEffect(() => {
    if (metaDataRef.current) {
      metaDataRef.current.runConfig = runConfig;
    }
  }, [runConfig]);

  useEffect(() => {
    return () => {
      webSocket?.current?.close();
    };
  }, []);

  // project 의 현재 상태를 알기 위한 ref
  const projectRef = useRef(project);

  useEffect(() => {
    projectRef.current = project;
  }, [project]);

  const serverRef = useRef(server);

  useEffect(() => {
    serverRef.current = server;
  }, [server]);

  const variableTableRef = useRef(variableTable);

  useEffect(() => {
    variableTableRef.current = variableTable;
  }, [variableTable]);

  // requestSeqInfo 10초마다 요청 (model의 Status를 알기 위한 임시 처리)
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    intervalId = setInterval(() => {
      if (projectRef.current) {
        requestSeqInfo(projectRef.current.fileName);
      }
    }, 10000);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [projectRef.current]);

  const getWebsocketHost = (url: string, isCustomServer: boolean): string => {
    // console.log(url);
    if (isCustomServer) {
      return 'ws://localhost:9000';
    }
    if (url.startsWith('https://')) {
      // For HTTPS URLs, use WSS (WebSocket Secure)
      return url.replace('https://', 'wss://');
    } else if (url.startsWith('http://')) {
      // For HTTP URLs, use WS (WebSocket)
      return url.replace('http://', 'ws://');
    }
  };

  const getServerList = async (): Promise<IApiReturnBasic> => {
    return await api.get<IApiReturnBasic>('/model_manager/server_list').then((res) => {
      if (res.success) {
        setServerList(res.data as IModelRunnerServer[]);
      }
      return res;
    });
  };

  const checkSocketEmulator = async () => {
    try {
      const response = await fetch('http://localhost:9000/openapi.json');
      return response.ok;
    } catch (error) {
      return false;
    }
  };

  const onOpen = (msg: Event) => {
    console.log('MMS connection opened', msg);

    addMessage('Successfully connected to Model Manager Server');
    //todo test 필요
    // requestProjectList();
    setLogData((prevLogData) => prevLogData + 'Connected to PMv server with ID:');
    setIsConnected(true);
  };

  const onClose = (event: CloseEvent) => {
    if (event.wasClean) {
      console.log('MMS websocket closed successfully');
      webSocket.current = null;
      setIsConnected(false);
    }
  };

  const onMessage = (msg: MessageEvent) => {
    const receive: IReceiveMessage = JSON.parse(msg.data);
    try {
      console.debug(`onMessage(msg) ${receive.Cmd} : `, receive);
      let dynamicMessage: string;
      const newLog = `Received response: ${JSON.stringify(receive, null, 2)}\n`;
      setLogData((prevLogData) => prevLogData + newLog);

      if (receive.Cmd === 'ReqProjectConnect') {
        const data = receive.Data as IReceiveMessageRequestProjectConnectData;
        projectRef.current.fileName = data.ProjectName;
        setProject((prev) => ({...prev, fileName: data.ProjectName}));
        // console.log('IReceiveMessageRequestProjectConnectData data = ', data);
        // console.log('projectRef = ', projectRef);
      } else if (receive.Cmd === 'ReqProjectOpen') {
        const data = receive.Data as IReceiveMessageRequestProjectOpenData;
        updateProject({fileName: data.ProjectName});
      } else if (receive.Cmd === 'ReqProjectList') {
        const {ProjectList, Notify} = receive.Data as IReceiveMessageRequestProjectListData;

        const refined: IModelRunnerProject[] = ProjectList.map((project: IReceiveProjectInfo) => {
          return {
            projectName: project.ProjectName,
            alias: getProjectFileName(project.FileName),
            server: project.Server,
            fileName: project.FileName,
            owner: project.Owner,
            pmvUser: project.PMvUser,
            isPublic: project.IsPublic,
            isEditable: project.IsEditable,
            isUserProject: project.IsUserProject,
            isRunning: project.IsRunning,
            description: project.Description,
            modelInfo: [],
            seqInfo: [],
            ioInfo: [],
            model_list: [],
            isRun: false,
            isBatch: false
          };
        });
        setProjectList(refined);

        dynamicMessage = Notify.Message;
      } else if (receive.Cmd === 'ReqProjectInfo') {
        const {ModelInfo, IOInfo, SeqInfo, ProjectName, Notify} = receive.Data as IReceiveMessageRequestProjectInfoData;
        /* if (Notify.ErrorCode === -1) {
          console.warn(Notify.Message);
          return;
        }*/
        setIsValidProject(true);

        const merged: Partial<IModelRunnerProject> = {};
        if (ModelInfo?.length > 0) {
          merged.modelInfo = ModelInfo;
        }
        if (IOInfo?.length > 0) {
          merged.ioInfo = IOInfo;

          const refined = IOInfo.flatMap((ioInfo) =>
            ioInfo.Data.map((ioInfoData) => ({
              variableName: ioInfoData.Tag,
              flowType: ioInfo.Type,
              variableValue: ioInfoData.Value,
              path: [],
              keys: getUniqueKey()
            }))
          );
          setVariableTable(refined);
          // setImportList(refined.filter((row) => row.flowType === 'Import'));
          // setExportList(refined.filter((row) => row.flowType === 'Export'));
        }
        if (SeqInfo?.length > 0) {
          merged.seqInfo = SeqInfo;
          const [firstSeq] = merged.seqInfo;
          setSeqInfo(firstSeq);
          setStatusMessage(firstSeq.Status);

          //todo setIsRun state 이전 상태
          if (firstSeq.Command === 'WAIT_IMPORT' || firstSeq.Status === 'Completed') {
            const conf = metaDataRef.current.runConfig;
            // console.log(conf);
            // console.log(receive.Data['ProjectName']);
            if (conf.autoRun) {
              if (conf?.intervalValue > 0) {
                setTimeout(() => runModel(receive.Data['ProjectName']), conf?.intervalValue * conf?.intervalUnit);
              }
            } else {
              if (conf.batchRun) {
                // localDatabase?.ref?.current?.next().then((success) => {
                //   if (success) {
                //     setTimeout(() => runCode(), 100);
                //   } else {
                //     setIsRun(false);
                //   }
                // });
              } else {
                setIsRun(false);
              }
            }
          }
        }
        // seq만 요청했을 경우 처리 로직 (10초마다 요청중)
        if (SeqInfo?.length > 0 && ModelInfo?.length === 0 && IOInfo?.length === 0) {
          // debugger;
          // merged.seqInfo = SeqInfo;
          const [firstSeq] = merged.seqInfo;

          setSeqInfo(firstSeq);
          setStatusMessage(firstSeq.Status);
          // run 조건에 사용될 seqInfo 업데이트
          updateProject({seqInfo: merged.seqInfo});
        } else {
          updateProject(merged);
          dynamicMessage = `Project ${ProjectName}: Information has been updated.`;
        }

        /*  const found = projectList.find((prj) => prj.fileName === project.fileName);
        if (found) {
          updateProject({...found, ...merged});
        }*/
      } else if (receive.Cmd === 'SendProjectInput') {
        dynamicMessage = `Project ${
          (receive.Data as IReceiveMessageSendProjectInputData).ProjectName
        }: Data Successfully imported.`;
        // status 변환 update Seq요청
        requestSeqInfo((receive.Data as IReceiveMessageSendProjectInputData).ProjectName);
      } else if (receive.Cmd === 'ExportReady') {
        const [server, projectName, ioType, ioName] = receive.Sender.split('::');
        dynamicMessage = `Project ${projectName}: Export for "${ioName}" ready.`;
        // status 변환 update Seq요청
        requestSeqInfo(projectName);
        requestProjectOutput(projectName);
      } else if (receive.Cmd === 'ReqProjectOutput') {
        const {ProjectName, Notify, OutputList} = receive.Data as IReceiveMessageRequestProjectOutputData;
        // let data = receive.Data;
        // status 변환 update Seq요청
        requestSeqInfo(ProjectName);
        if (Notify.ErrorCode === 0) {
          const projectName = ProjectName;
          dynamicMessage = `Project ${projectName}: Data has been successfully exported.`;

          const convertOATimeToDate = (oaTime: number) => {
            const OADateEpoch = new Date(Date.UTC(1899, 11, 30));
            const msPerDay = 24 * 60 * 60 * 1000;
            const dateInMs = OADateEpoch.getTime() + oaTime * msPerDay;
            const date = new Date(dateInMs);

            // Format the date as 'YYYY-MM-DD HH:MM:SS'
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          };

          const newRow = {} as IOutputList;
          newRow.DataOATime = convertOATimeToDate(OutputList[0].DataOATime);

          OutputList[0].Data.forEach((item: IoInfoData) => {
            if (item.Tag !== 'TimeTag') {
              newRow[item.Tag] = item.Value;
            } else {
              newRow[item.Tag] = convertOATimeToDate(item.Value);
            }
          });

          // export row node db 저장
          if (variableTableRef.current) {
            const updatedVariableTable = variableTableRef.current.map((variable) => {
              if (variable.flowType === 'Export' && newRow[variable.variableName] !== undefined) {
                return {...variable, variableValue: newRow[variable.variableName]};
              }
              return variable;
            });

            setVariableTable(updatedVariableTable);

            const node_data_list = updatedVariableTable
              ?.filter((variable) => variable.variableDbType === 'cloud')
              .filter((variable) => variable.flowType === 'Export')
              .map((variable) => {
                const [db, ...path] = variable.path;
                return {
                  database: db,
                  path: path,
                  records: [[Date.now() / 1000, variable.variableValue]]
                };
              });

            api.post('/db_manage/store_node_data_list', {node_data_list: node_data_list});
          }

          sourceDataBaseRef?.current?.ref?.current?.getOutflowResult({outflowResult: newRow});
        }
      } else if (receive.Cmd === 'SendProjectAction') {
        const {ProjectName, Action, Notify} = receive.Data as IReceiveMessageSendProjectActionData;
        if (Action === 'RunSequence') {
          if (Notify?.Message === 'Sequence ASDT: SeqStart') {
            if (projectRef.current?.projectName === ProjectName) {
              // sendProjectInput(ProjectName, projectRef.current?.seqInfo[0]?.Name);
            }
          }
          requestSeqInfo(ProjectName);
        }
      } else if (receive.Cmd === 'ReqProjectCreate') {
        const {Notify} = receive.Data as IReceiveMessageRequestProjectCreateData;
        if (Notify.ErrorCode === 0) {
          // alert('Successfully Created');
          // requestProjectList();
        }
      }
      if (dynamicMessage) {
        addMessage(dynamicMessage);
      }
      setSocketResponse(receive);
    } catch (error) {
      console.error('수신한 메시지를 처리하는 중 오류 발생:', error);
      if (receive.Cmd === 'ReqProjectInfo') {
        setIsValidProject(false);
      }
    }
  };

  const connectToServer = async (server: IModelRunnerServer) => {
    console.log('Try to connect MMS!');
    // debugger;
    const MY_TOKEN = token;
    let isCustom = Boolean(server?.custom);
    if (isCustom) {
      let connectable = await checkSocketEmulator();
      if (!connectable) {
        return;
      }
    }

    let res: IApiReturnBasic;
    let payload = {
      pmv_id: senderId,
      server_name: server.name,
      server_ip: server.ip,
      server_port: server.port
    };
    if (isCustom) {
      res = await api.post<IApiReturnBasic>(`/model_manager/connect`, payload, {host: 'http://localhost:9000'});
    } else {
      res = await api.post<IApiReturnBasic>(`/model_manager/connect`, payload);
    }

    const websocketHost = getWebsocketHost(API_HOST, isCustom);
    let websocketUrl: string = `${websocketHost}/ws?token=${MY_TOKEN}&pmv_id=${senderId}`;
    if (isCustom) {
      const savedServer = LocalStorageManager.getItem<IApiServer>('API_SERVER');
      websocketUrl = `${websocketUrl}&server_name=${savedServer?.area}`;
    }

    if (res.success) {
      const serverObj = {...server} as IModelRunnerServer;
      setServer(serverObj);
      serverRef.current = serverObj;
      webSocket.current = new WebSocket(`${websocketUrl}`);
      webSocket.current.onopen = onOpen;
      webSocket.current.onclose = onClose;
      webSocket.current.onmessage = onMessage;
    } else {
      if (webSocket?.current) {
        webSocket.current?.close();
      }
    }
    return res;
  };

  const disconnectFromServer = () => {
    let dynamicMessage: string;
    if (webSocket.current) {
      webSocket.current.close();
      webSocket.current = null;
      setLogData((prevLogData) => prevLogData + 'Disconnected from server\n');
      dynamicMessage = `Disconnected from server`;
    } else {
      setLogData((prevLogData) => prevLogData + 'No active connection to disconnect\n');
      dynamicMessage = 'No active connection to disconnect';
    }
    if (dynamicMessage) {
      addMessage(dynamicMessage);
    }
  };

  const addMessage = (message: string) => {
    setMessages((prevMessages) => prevMessages + message + '\n');
  };

  const sendMessage = ({type, cmd, receiver, data}: ISendMessage) => {
    console.debug('sendMessage(type, cmd, receiver, data) = ', type, cmd, receiver, data);
    webSocket.current.send(
      JSON.stringify({
        Type: type,
        Cmd: cmd,
        Sender: senderId,
        Receiver: receiver,
        Data: data
      })
    );
  };

  const requestProjectOpen = (fileName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectOpen',
      receiver: server.name,
      data: {
        ProjectName: fileName,
        RunMacro: true
      }
    });
  };

  const requestProjectConnect = (fileName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectConnect',
      receiver: server.name,
      data: {
        ProjectName: fileName,
        PMvUser: senderId
      }
    });
  };

  const requestProjectTerminate = (fileName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectTerminate',
      receiver: server.name,
      data: {
        ProjectName: fileName,
        RunMacro: true
      }
    });
  };

  const requestProjectOutput = (fileName: string) => {
    if (projectRef.current.fileName === fileName) {
      let ioName = projectRef.current?.modelInfo[0]?.Name;
      let ioData = projectRef.current?.ioInfo.find((io) => io.Type === 'Export' && io.Name === ioName)?.Data;
      const modifiedData = ioData.map((item) => ({
        Tag: item.Tag,
        Unit: ''
      }));
      sendMessage({
        type: 'Cmd',
        cmd: 'ReqProjectOutput',
        receiver: serverRef.current.name,
        data: {ProjectName: fileName, ExportName: ioName, OutputList: modifiedData}
      });
    }
  };

  const sendProjectInput = async (fileName: string, ioName: string) => {
    const inflowForLog = {};

    let variableInput = await Promise.all(
      variableTableRef.current.map(async (row) => {
        if (row.flowType === 'Import') {
          const searchResult = valueGroupObjectState?.[0]?.[row.keys]?.value?.[0]?.[1];
          const searchResultFormDatasheet = focusedRowDataRef.current?.[row?.path?.[1]];
          if (searchResult) {
            inflowForLog[row?.variableName] = searchResult;
            return `${row?.variableName.replace(/ /g, '_')} = ${JSON.stringify(searchResult || '')}`;
          } else if (searchResultFormDatasheet) {
            inflowForLog[row?.variableName] = searchResultFormDatasheet;
            return {
              Tag: row?.variableName,
              Value:
                row?.variableName === 'TimeTag'
                  ? convertToOATime(searchResultFormDatasheet)
                  : searchResultFormDatasheet,
              Unit: row.variableName === 'TimeTag' ? 'OADate' : ''
            };
          }
        }
      })
    );

    variableInput = variableInput.filter((item) => item !== undefined);

    sendMessage({
      type: 'Cmd',
      cmd: 'SendProjectInput',
      receiver: serverRef.current.name,
      data: {
        ProjectName: fileName,
        ImportName: ioName,
        InputList: {
          Validity: true,
          DataOATime: convertToOATime(Date.now()),
          Data: variableInput
        }
      }
    });
  };

  const requestProjectList = (server: IModelRunnerServer) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectList',
      receiver: server.name,
      data: {
        MasterProject: true,
        UserProject: true,
        IncludeRunning: true,
        IncludeSubMMS: false
      }
    });
  };

  const requestProjectInfo = (fileName: string, hasModelInfo = true, hasSeqInfo = true, hasIoInfo = true) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectInfo',
      receiver: serverRef.current.name,
      data: {ProjectName: fileName, ModelInfo: hasModelInfo, SeqInfo: hasSeqInfo, IOInfo: hasIoInfo}
    });
  };

  const createProject = (fileName: string, projectName: string) => {
    // console.log(fileName);
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectCreate',
      receiver: serverRef.current.name,
      data: {
        MasterProjectName: fileName,
        UserProjectName: projectName + '.mms',
        RunMacro: true
      }
    });
  };

  const requestSeqInfo = (fileName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'ReqProjectInfo',
      receiver: serverRef.current.name,
      data: {ProjectName: fileName, ModelInfo: false, SeqInfo: true, IOInfo: false}
    });
  };

  const startSequence = (projectName: string) => {
    sendMessage({
      type: 'Cmd',
      cmd: 'SendProjectAction',
      receiver: server.name + '::' + project.projectName,
      data: {ProjectName: projectName, Action: 'RunSequence', Data: {Name: 'ASDT', Parameters: [-1]}}
    });
  };

  const updateServer = (partial: Partial<IModelRunnerServer>): void => {
    if (!partial || Object.keys(partial).length === 0) return;
    setServer((prev) => ({...prev, ...partial}));
  };

  const updateProject = (partial: Partial<IModelRunnerProject>): void => {
    if (!partial || Object.keys(partial).length === 0) return;
    setProject((prev) => {
      const merged = {...prev, ...partial};
      projectRef.current = merged;
      return merged;
    });
  };

  const resetServer = (): void => {
    setIsConnected(false);
    setServer(undefined);
    webSocket?.current?.close();
  };

  const resetProject = (): void => {
    setProject(undefined);
  };

  const updateRunConfig = (partial: Partial<IModelRunnerRunConfig>): void => {
    if (!partial || Object.keys(partial).length === 0) return;
    setRunConfig((prev) => ({...prev, ...partial}));
  };

  const runModel = (fileName: string) => {
    const firstInfo = metaDataRef.current.project?.seqInfo?.[0];
    //todo firstInfo 의 seqInfo 가 업데이트 안됨. 이전 상태임
    // console.log('firstInfo = ', firstInfo);
    if (!firstInfo) return;
    const {Status, Name, Command} = firstInfo;

    if (Command === 'WAIT_IMPORT' || Status === 'Completed') {
      // 현재는 사용하지 않지만 복구 가능성 있음
      /* startSequence(fileName);*/
      sendProjectInput(fileName, Name);
      updateProject({isRun: true});
    }
  };

  // model 의 status, command 값을 보내줌. 용도에 따라 statusMapping 의 value 혹은 key 값을 사용
  const status = (modelStatus: ISeqInfo, type: 'key' | 'value'): {sts: string; cmd: string} => {
    if (!modelStatus) {
      return {sts: '', cmd: ''};
    }

    const {Status: sts, Command: cmd} = modelStatus;

    if (type === 'key') {
      return {
        sts: sts || '',
        cmd: cmd || ''
      };
    }

    if (type === 'value') {
      return {
        sts: statusMapping[sts] || '',
        cmd: statusMapping[cmd] || ''
      };
    }
  };

  return {
    serverList,
    server,
    projectList,
    project,
    variableTable,
    importList,
    exportList,
    socketResponse,
    logData,
    messages,
    runConfig,
    isConnected,
    isValidProject,
    statusMessage,
    seqInfo,

    updateServer,
    updateProject,
    resetServer,
    resetProject,
    requestProjectList,
    requestProjectInfo,
    createProject,
    sendMessage,
    requestProjectOpen,
    requestProjectConnect,
    requestProjectTerminate,
    sendProjectInput,
    connectToServer,
    disconnectFromServer,
    getServerList,
    setMetaData,
    setVariableTable,
    updateRunConfig,
    runModel,
    status
  };
}

export default useModelRunnerLoader;
