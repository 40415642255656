import React, {Dispatch, RefObject, useEffect, useState} from 'react';
import {IPosition} from 'components/common/types';
import {useViewport} from 'reactflow';

const getValueByRange = (v: number, min: number, max: number) => {
  if (v < min) return min;
  if (v > max) return max;
  return v;
};

type IPadding = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};
type IReturn = [IPosition, (e: React.MouseEvent) => void, Dispatch<React.SetStateAction<IPosition>>];

function useDraggable<T>(
  boundaryRef: RefObject<HTMLElement>,
  contentRef: RefObject<HTMLElement>,
  defaultPosition?: IPosition,
  boundaryPadding: IPadding = {top: 10, right: 10, bottom: 10, left: 10},
  isZoomAffected = true,
  metaData?: T,
  key?: keyof T,
  direction: 'x' | 'y' = 'x'
): IReturn {
  const [position, setPosition] = useState<IPosition>(defaultPosition || {x: 0, y: 0});
  const viewport = useViewport();

  useEffect(() => {
    if (!key) return;
    if (metaData) {
      if (direction === 'x') {
        setPosition({x: (metaData[key] as number) ?? defaultPosition.x, y: 0});
      }
      if (direction === 'y') {
        setPosition({x: 0, y: (metaData[key] as number) ?? defaultPosition.y});
      }
    }
  }, []);

  useEffect(() => {
    if (!key) return;
    if (metaData) {
      metaData[key] = position[direction] as T[keyof T];
    }
  }, [position, metaData]);

  const onMouseDown = (e: React.MouseEvent): void => {
    const {pageX, pageY} = e;
    const startPosition = {x: pageX, y: pageY};
    const boundary = boundaryRef.current.getBoundingClientRect();
    const content = contentRef.current.getBoundingClientRect();

    const onMouseMove = (e: MouseEvent): void => {
      const {pageX, pageY} = e;
      // console.log(`mouse move x:${pageX} y:${pageY}`, viewport.zoom);
      const deltaX = pageX - startPosition.x;
      const deltaY = pageY - startPosition.y;

      const zoomRatio = isZoomAffected ? viewport.zoom : 1;
      const x = getValueByRange(
        position.x + deltaX / zoomRatio,
        Math.floor(boundaryPadding.left),
        Math.floor((boundary.width - content.width) / zoomRatio - boundaryPadding.right)
      );
      const y = getValueByRange(
        position.y + deltaY / zoomRatio,
        Math.floor(boundaryPadding.top),
        Math.floor((boundary.height - content.height - boundaryPadding.bottom) / zoomRatio)
      );
      setPosition({x, y});
    };

    const onMouseUp = (): void => {
      document.removeEventListener('mousemove', onMouseMove);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp, {once: true});
  };

  return [position, onMouseDown, setPosition];
}

export default useDraggable;
