import {memo, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import UrlSearchBar from 'components/pc/widgets/parts/UrlSearchBar';
import {IWebWidgetData, IWidgetNodeData} from 'components/pc/types';
import styled from 'styled-components';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {getWidgetTitle} from 'utils/processCanvas-functions';

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

function WebWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const [url, setUrl] = useState('/widget/about-blank.html');
  const {setNodes} = useReactFlow();
  const {onCanvasChange} = useContext(ProcessCanvasContext);

  useEffect(() => {
    if (data?.metaData) {
      const {url} = data.metaData as IWebWidgetData;
      setUrl(url);
    }
  }, [data]);

  const saveData = (path: string) => {
    if (path === '/widget/about-blank.html') {
      return;
    }
    const metaData = {url: path};
    setNodes((nodes) => nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData}} : node)));
    onCanvasChange();
  };

  const onConfirm = (path: string): void => {
    saveData(path);
    setUrl(path);
  };

  return (
    <WidgetContainer {...rest} data={data} type="WebWidget">
      <WidgetHeader
        {...rest}
        type="WebWidget"
        icon={data.icon}
        title={getWidgetTitle({data})}
        suffix="- Web Browser"
        id={id}
      />
      <WidgetActionPanel>
        <UrlSearchBar defaultUrl={url !== '/widget/about-blank.html' && url} onConfirm={onConfirm} />
      </WidgetActionPanel>
      <WidgetBody>
        <IFrame src={url || '/widget/about-blank.html'} title={data.title}></IFrame>
      </WidgetBody>
    </WidgetContainer>
  );
}
export default memo(WebWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
