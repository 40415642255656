import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {IModelRunnerWidgetData, IWidgetMetaData, IWidgetNodeData} from 'components/pc/types';
import ModelRunnerBodyTop from 'components/pc/widgets/modelRunner/ModelRunnerBodyTop';
import ModelRunnerBottom from 'components/pc/widgets/modelRunner/ModelRunnerBottom';
import ModelRunnerActionPanel from 'components/pc/widgets/modelRunner/ModelRunnerActionPanel';
import {IValueGroupObj} from 'components/pc/widgets/modelRunner/types';
import WidgetConfigLayer from 'components/pc/widgets/parts/WidgetConfigLayer';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import WidgetIconModeHeader from 'components/pc/widgets/parts/WidgetIconModeHeader';
import ResizeHandle from 'components/common/resizer/ResizeHandle';
import useDraggable from 'utils/useDraggable';
import styled from 'styled-components';
import useLocalDatabase from 'components/pc/widgets/datasheet/useLocalDatabase';
import ModelRunnerResult from 'components/pc/widgets/modelRunner/ModelRunnerResult';
import ModelRunnerVariableTable from 'components/pc/widgets/modelRunner/ModelRunnerVariableTable';
import useModelRunnerLoader from 'components/pc/widgets/modelRunner/useModelRunnerLoader';
import ModelRunnerInitializeModal from 'components/pc/widgets/modelRunner/ModelRunnerInitializeModal';
import useIconMode from 'components/pc/widgets/common/useIconMode';
import ModelRunnerStatusBar from 'components/pc/widgets/modelRunner/ModelRunnerStatusBar';
import WidgetModal, {IWidgetModal} from 'components/common/modal/WidgetModal';

const TopResizeArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #fff;
  z-index: 20;
`;
function ModelRunnerWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const defaultMetaData = {resizeHeight: 500};
  const metaData: IModelRunnerWidgetData = (data?.metaData ?? defaultMetaData) as IModelRunnerWidgetData;
  const reactFlow = useReactFlow();
  const valueGroupObjectState = useState<IValueGroupObj>({});
  const initializeModalState = useState<boolean>(true);
  const [isShowInitializeModalState] = initializeModalState;

  const serverConnectedState = useState<boolean>(false);
  const projectConnectedState = useState<boolean>(false);

  const boundaryTopRef = useRef(null);
  const dragHandleTopRef = useRef(null);

  const defaultTopPosition = {x: 0, y: 534};
  const defaultTopPadding = {top: 90, right: 0, bottom: 1, left: 0};
  const [editorBodyTopPosition, onMouseDownTopResizeHandle, setEditorBodyTopSize] =
    useDraggable<IModelRunnerWidgetData>(
      boundaryTopRef,
      dragHandleTopRef,
      defaultTopPosition,
      defaultTopPadding,
      true,
      metaData,
      'resizeHeight',
      'y'
    );

  const {localDatabase, sourceDatabase, dynamicHierarchy} = useLocalDatabase(id);

  const isRunState = useState(false);
  // const [isRun] = isRunState;
  // const isRunRef = useRef(null);
  // isRunRef.current = isRun;

  const [stopRequest, setStopRequest] = useState(null);
  const stopRequestRef = useRef(null);
  stopRequestRef.current = stopRequest;

  useEffect(() => {
    const defaultMetaData = {resizeHeight: 500};
    const metaData: IModelRunnerWidgetData = (data?.metaData ?? defaultMetaData) as IModelRunnerWidgetData;
    data.metaData = metaData as IWidgetMetaData;
    setMetaData(metaData);
  }, []);

  const [focusedRowData, setFocusedRowData] = useState({});
  const focusedRowDataRef = useRef(focusedRowData);
  // const variableTableRef = useRef(variableTable);
  const sourceDataBaseRef = useRef(sourceDatabase);

  const loader = useModelRunnerLoader({
    id,
    focusedRowDataRef,
    sourceDataBaseRef,
    isRunState,
    valueGroupObjectState
  });

  const {project, logData, messages, disconnectFromServer, updateProject, setMetaData, runModel} = loader;

  useEffect(() => {
    focusedRowDataRef.current = focusedRowData;
  }, [focusedRowData]);

  useEffect(() => {
    sourceDataBaseRef.current = sourceDatabase;
  }, [sourceDatabase]);

  const projectNameList = loader.projectList?.map((item) => item.projectName);

  //todo 추후에 기능이 필요할 경우 다시 복원 필요
  const stopModel = async (projectName: string) => {
    updateProject({isRun: false});
  };

  const setIsBatch = (projectName: string, bool: boolean) => {
    updateProject({isBatch: bool});
  };

  // const iconModeState = useState<CSSProperties>(null);
  // const [iconMode, setIconMode] = iconModeState;

  useEffect(() => {
    if (metaData) {
      const {resizeHeight} = metaData;
      setEditorBodyTopSize({x: 0, y: resizeHeight || 0});
    }
  }, []);

  useEffect(() => {
    const metaData = data.metaData as IModelRunnerWidgetData;
    if (metaData) {
      metaData.resizeHeight = editorBodyTopPosition.y;
    }
  }, [editorBodyTopPosition]);

  useEffect(() => {
    const data = localDatabase?.ref?.current?.dbData;
    const result = {};
    if (data) {
      let headersRowIndex = -1;
      for (let i = 0; i < data.length; i++) {
        if (data[i].some((cell) => cell !== undefined && cell !== null && cell !== '')) {
          headersRowIndex = i;
          break;
        }
      }

      const headers = data[headersRowIndex];
      const rows = data?.slice(1); // header row slice

      const row = rows?.[localDatabase?.ref?.current?.focusedRowIdx - 1];

      for (let i = 0; i < headers?.length; i++) {
        const tag = headers[i];
        if (!tag) continue;
        result[tag] = row?.[i] !== undefined ? row?.[i] : null;
      }
      setFocusedRowData(result);
    }
  }, [localDatabase?.ref?.current?.dbData, localDatabase?.ref?.current?.focusedRowIdx]);

  const onToggleRun = (isRun: boolean): void => {
    console.log('project = ', project);
    if (isRun) {
      console.log('project = ', project.fileName);
      runModel(project.fileName);
    } else {
      const now = Date.now();
      setStopRequest(now);
    }
  };

  const {isIconMode, onToggleIconMode} = useIconMode(id, metaData, 248, 144);

  const widgetBodyHeight = boundaryTopRef.current?.offsetHeight || 0;
  const widgetBottomHeight = widgetBodyHeight - editorBodyTopPosition.y - 7;

  const getRandomCpu = () => Math.floor(Math.random() * (80 - 50 + 1)) + 50; // 50~80MB
  const getRandomMemory = () => Math.floor(Math.random() * (150 - 100 + 1)) + 100; // 100~150MB
  const [cpu, setCpu] = useState<number>(getRandomCpu());
  const [memory, setMemory] = useState<number>(getRandomMemory());

  /*
  // CPU, Memory 랜덤 값 업데이트
  useEffect(() => {
    const interval = setInterval(() => {
      setCpu(getRandomCpu());
      setMemory(getRandomMemory());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
*/

  // const modelStatus = loader.status(loader.seqInfo);

  const onBeforeClose = (): void => {
    const {project} = loader;
    if (project) loader.requestProjectTerminate(loader.project.fileName);
    setIsShowCloseModal(false);
    reactFlow.deleteElements({nodes: [{id}]});
  };

  const keepAlive = (): void => {
    const {isConnected, disconnectFromServer} = loader;
    if (isConnected) disconnectFromServer();
    setIsShowCloseModal(false);
    reactFlow.deleteElements({nodes: [{id}]});
  };

  const closeMessage =
    "When you close a Model Runner, the Model's state in server changes to unactivated and becomes available for other users.\n You can choose one of the following options:\n" +
    '1. Shutdown the Model Manager\n' +
    '2. Standby the Model Manager without shutdown';

  const [isShowCloseModal, setIsShowCloseModal] = useState(false);
  const [hasCloseConfirm, setHasCloseConfirm] = useState(false);
  const closeModalOptions = {
    title: 'Close Model Runner',
    content: closeMessage,
    isShowCancel: true,
    isShowSecondary: true,
    isShowConfirm: true,
    secondaryLabel: 'Standby',
    confirmLabel: 'Shutdown',
    onCancel: () => setIsShowCloseModal(false),
    onSecondary: keepAlive,
    onConfirm: onBeforeClose
  };

  const onCloseWidget = (): void => {
    setIsShowCloseModal(true);
  };

  // socket, project 연결을 한 경우에는 모달 옵션이 달라져야 함
  useEffect(() => {
    const {isConnected, project} = loader;
    if (isConnected && project) {
      setHasCloseConfirm(true);
    }
  }, [loader.isConnected, loader.project]);

  return (
    <WidgetContainer {...rest} data={data} type="ModelRunnerWidget" iconMode={isIconMode}>
      <WidgetHeader
        type="ModelRunnerWidget"
        icon={data.icon}
        id={id}
        title={getWidgetTitle({data})}
        suffix="- Model Runner"
        hasCloseConfirm={hasCloseConfirm}
        onClose={onCloseWidget}
      />
      {isIconMode && <WidgetIconModeHeader title={getWidgetTitle({data})} />}
      <WidgetActionPanel
        disabled={isShowInitializeModalState}
        padding={0}
        height={!isIconMode ? 60 : 123}
        align="center"
      >
        <ModelRunnerActionPanel
          loader={loader}
          iconMode={isIconMode}
          isRunState={isRunState}
          projectNameList={projectNameList}
          connectedDatasheetSubjectInfo={localDatabase}
          onClickIconModeIcon={onToggleIconMode}
          onToggleRun={onToggleRun}
        />
      </WidgetActionPanel>
      <WidgetBody ref={boundaryTopRef} actionMenuHeight={60}>
        <ModelRunnerBodyTop height={editorBodyTopPosition.y}>
          <ModelRunnerVariableTable
            loader={loader}
            id={id}
            dynamicHierarchy={dynamicHierarchy}
            valueFromDatasheet={focusedRowData}
            valueGroupObjectState={valueGroupObjectState}
          />
        </ModelRunnerBodyTop>
        <TopResizeArea>
          <ResizeHandle ref={dragHandleTopRef} onMouseDown={onMouseDownTopResizeHandle} />
          <ModelRunnerBottom height={widgetBottomHeight}>
            <ModelRunnerResult messages={messages} logData={logData} />
          </ModelRunnerBottom>
        </TopResizeArea>
        <ModelRunnerStatusBar loader={loader} cpu={cpu} memory={memory} />
        {!isIconMode && isShowInitializeModalState && (
          <ModelRunnerInitializeModal
            loader={loader}
            metaData={metaData}
            initializeModalState={initializeModalState}
            serverConnectedState={serverConnectedState}
            projectConnectedState={projectConnectedState}
            disconnectFromServer={disconnectFromServer}
          />
        )}
      </WidgetBody>
      <WidgetConfigLayer
        title="ModelRunner Environment Package List"
        isShow={false}
        onClose={() => {}}
      ></WidgetConfigLayer>
      <WidgetModal isShow={isShowCloseModal} options={closeModalOptions} />
    </WidgetContainer>
  );
}
export default ModelRunnerWidget;
