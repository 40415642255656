import {ReactElement, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common/index';
import {Button} from 'components/forms';
import styled from 'styled-components';
import {VariantTypes} from 'components/forms/types';

const ConfirmButton = styled(Button)`
  min-width: 100px;
`;

export type IWidgetModal = {
  title?: string | null;
  content?: string | ReactElement | ReactElement[];
  isShowCancel?: boolean;
  isShowSecondary?: boolean;
  isShowConfirm?: boolean;
  cancelLabel?: string | null;
  secondaryLabel?: string | null;
  confirmLabel?: string | null;
  confirmVariant?: VariantTypes; // confirm 버튼의 스타일 지정
  hasConfirmToBusy?: boolean; // confirm 버튼을 클릭하면 비활성가 필요한 버튼을의 상태를 전환
  onCancel?(): void;
  onSecondary?(): void;
  onConfirm?(): void;
};

type IProps = {
  isShow: boolean;
  options?: IWidgetModal;
};

function WidgetModal({isShow, options}: IProps): ReactElement {
  const [isBusy, setIsBusy] = useState(undefined);

  const onConfirmModal = () => {
    if (options.hasConfirmToBusy) setIsBusy(true);
    options?.onCancel?.();
    options?.onConfirm?.();
  };

  if (isShow) {
    return (
      <ModalWrapper type="widget">
        <ModalHeader onClose={options.onCancel}>{options.title ?? 'Widget Dialog'}</ModalHeader>
        <ModalBody>{options.content}</ModalBody>
        <ModalFooter>
          {(options.isShowConfirm === undefined || options.isShowConfirm) && (
            <ConfirmButton variant={options.confirmVariant} disabled={isBusy} onClick={onConfirmModal}>
              {options.confirmLabel || 'Ok'}
            </ConfirmButton>
          )}
          {options.isShowSecondary && (
            <Button variant="dark" disabled={isBusy} onClick={options.onSecondary}>
              {options.secondaryLabel || 'Close'}
            </Button>
          )}
          {options.isShowCancel && (
            <Button variant="cancel" disabled={isBusy} onClick={options.onCancel}>
              {options.cancelLabel || 'Cancel'}
            </Button>
          )}
        </ModalFooter>
      </ModalWrapper>
    );
  } else {
    return undefined;
  }
}

export default WidgetModal;
