import {ReactElement, useCallback, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {ResizeHandle} from 'components/common';
import useDraggable from 'utils/useDraggable';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import useLocalDatabase from 'components/pc/widgets/datasheet/useLocalDatabase';
import {ChartTableArea} from 'components/pc/widgets/common/shared-styled-components';
import {
  LightningChartProvider,
  ProfileChartContainer,
  useProfileChartLoader
} from 'components/pc/widgets/profile-chart';
import {IProfileChartWidgetData, IWidgetMetaData} from 'components/pc/types';
import dayjs from 'dayjs';
import {INode} from 'api/data-types';
import ProfileChartSeriesList from 'components/pc/widgets/profile-chart/ProfileChartSeriesList';
import useFrameless from 'components/pc/widgets/common/useFrameless';

function ProfileChartWidget({data, id, ...rest}: NodeProps): ReactElement {
  const metaData = data?.metaData; // || {chartHeight: 60};
  data.metaData = metaData as IWidgetMetaData;
  const {customizedTitle, icon, title, type} = data;
  const {setNodes} = useReactFlow();
  const boundaryRef = useRef(null);
  const dragHandleRef = useRef(null);
  const defaultPosition = {x: 0, y: 250};
  const defaultPadding = {top: 60, right: 0, bottom: 0, left: 0};
  const [seriesPosition, onMouseDownResizeHandle, setInitialChartSize] = useDraggable<IProfileChartWidgetData>(
    boundaryRef,
    dragHandleRef,
    defaultPosition,
    defaultPadding,
    true,
    metaData,
    'chartHeight',
    'y'
  );

  const [isShowNodeSelector, setIsShowNodeSelector] = useState(false);
  const loader = useProfileChartLoader();
  const {localDatabase, dynamicHierarchy} = useLocalDatabase(id);

  const [titleInfo, setTitleInfo] = useState([]);

  const {isFrameless, isWidgetMouseEnter, setIsFrameless, onMouseEnter, onMouseLeave, onFrameless} = useFrameless();

  useEffect(() => {
    if (data?.metaData) {
      const {nodes, series, stackInfo, frameless, chartHeight} = data.metaData as IProfileChartWidgetData;
      // setInitialChartSize({x: 0, y: chartHeight || 60});
      setIsFrameless(Boolean(frameless));
      loader.setNodes(nodes);
      loader.setSeries(series);
      loader.setStackInfo(stackInfo);
    }
  }, []);

  const onSelectNode = useCallback((checkedList: string[]): void => {
    const parsed = checkedList.map((item) => JSON.parse(item));
    // console.log('>>> checkedList', checkedList, parsed);

    const localNodeData: INode[] = [];
    const server = [];
    // key_12345678_1234567890123 의 형식을 가지고 있다면 datasheet 로 판정
    const widgetKeyRegex = /^key_\d{8}_\d{13,}$/;
    // local database 를 조회하여 node 에 해당하는 데이터를 get_node_data_list 형태로 리턴
    parsed.forEach((node) => {
      const [database, nodeKey] = node;
      if (widgetKeyRegex.test(database)) {
        // node key(id) 를 받아 records 를 반환
        const getNodeData = (nodeKey: string): any[][] => {
          const sheetData = localDatabase.ref.current.dbData;
          const [fields] = sheetData;
          const nodeIndex = fields.findIndex((col) => col === nodeKey);
          const records = [];
          for (let i = 1; i < sheetData.length; ++i) {
            const row = sheetData?.[i];
            // timestamp 가 없으면 포함시키지 않음
            if (row?.[0]) {
              const timestamp = dayjs(row?.[0])?.unix();
              const value = Number(row?.[nodeIndex]);
              records.push([timestamp, value]);
            }
          }
          return records;
        };

        // node name 에 (unit) 형태가 포함되어 있으면 unit 으로 인식하고 추출
        const regex = /^([A-Za-z0-9_]+)\s*\(*([\w\s\/&;#-]+)\)*$/;
        const match = nodeKey.match(regex);

        localNodeData.push({
          database,
          node: [nodeKey],
          records: getNodeData(nodeKey),
          unit: match?.[2],
          description: localDatabase?.name
        });
      } else {
        server.push(node);
      }
      loader.load({nodeList: server, localNodeData});
    });
  }, []);

  useEffect(() => {
    const {series} = loader;
    // console.log('>>> series 변경', series);
    setTitleInfo(series?.map((item) => item?.name));
    const metaData = {series, frameless: isFrameless, chartHeight: seriesPosition.y};
    setNodes((nodes) => nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData}} : node)));
  }, [id, loader.series, seriesPosition, isFrameless]);

  return (
    <WidgetContainer {...rest} data={data} type={type} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <WidgetHeader
        {...rest}
        type={type}
        icon={icon}
        id={id}
        title={getWidgetTitle({titleData: titleInfo, data})}
        suffix="- Profile Chart"
        frameless={isFrameless}
        isWidgetMouseEnter={isWidgetMouseEnter}
        onFrameless={onFrameless}
      />
      <WidgetBody ref={boundaryRef} actionMenuHeight={60}>
        <LightningChartProvider>
          <ProfileChartContainer
            docked={data.docked}
            height={isFrameless ? '100%' : seriesPosition.y}
            loader={loader}
          />
        </LightningChartProvider>
        {!isFrameless && (
          <ChartTableArea>
            <ResizeHandle ref={dragHandleRef} onMouseDown={onMouseDownResizeHandle} />
            <ProfileChartSeriesList loader={loader} />
            {isShowNodeSelector && (
              <NodeSelectorRevision
                title="Select Nodes"
                selectedNodes={loader.nodes}
                onClose={() => setIsShowNodeSelector(false)}
                onSelect={onSelectNode}
              />
            )}
          </ChartTableArea>
        )}
      </WidgetBody>
    </WidgetContainer>
  );
}

export default ProfileChartWidget;
