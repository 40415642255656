import {ChangeEvent, memo, ReactElement, useEffect, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import styled from 'styled-components';
import {SelectForm} from 'components/forms';
import NoDataDisplay from 'components/common/NoDataDisplay';
import {IJmpWidgetData} from 'components/pc/types';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {getApiHost} from 'api/function';

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const list = [
  {
    value: 'doe',
    title: 'Functional DOE Equation for Simulation',
    url: 'FunctionalDOE_Equation_for_Simulation.htm'
  },
  {
    value: 'builder',
    title: 'Graph Builder',
    url: 'Graph Builder.html'
  }
];

const API_HOST = getApiHost();
const PATH = `${API_HOST}/jmp/html_folder/`;

function JmpWidget({data, id, ...rest}: NodeProps): ReactElement {
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [selected, setSelected] = useState<string>('');
  const {setNodes} = useReactFlow();

  useEffect(() => {
    if (data?.metaData) {
      const {value} = data.metaData as IJmpWidgetData;
      const found = list.find((item) => item.value === value);
      if (found) {
        setUrl(PATH + found.url);
        setSelected(value);
        setTitle(found.title);
      }
    }
  }, [data]);

  const onChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const {value} = e.target;
    const found = list.find((item) => item.value === value);
    if (found) {
      setUrl(PATH + found.url);
      setSelected(found.value);
      setTitle(found.title);
      const metaData = {value: found.value};
      setNodes((nodes) => nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData}} : node)));
    }
  };

  return (
    <WidgetContainer {...rest} data={data} type="JmpWidget">
      <WidgetHeader
        {...rest}
        type="JmpWidget"
        icon={data.icon}
        title={getWidgetTitle({titleData: title, data})}
        suffix="- JMP Dashboard"
        id={id}
      />
      <WidgetActionPanel>
        <SelectForm
          name="Please Select"
          placeholder="Please select"
          options={list}
          labelKey="title"
          value={selected}
          onChange={onChange}
        />
      </WidgetActionPanel>
      <WidgetBody>
        {url ? (
          <IFrame src={url} sandbox="allow-same-origin allow-scripts" title={data.title} />
        ) : (
          <NoDataDisplay title="JMP Dashboard" description="Please select dashboard." />
        )}
      </WidgetBody>
    </WidgetContainer>
  );
}
export default memo(JmpWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
