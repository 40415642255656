import {ReactElement, useRef} from 'react';
import classNames from 'classnames';
import {IAppMenu} from 'components/menu/types';
import MetaPfdFileList from 'components/menu/MetaPfdFileList';
import ActionMenuWidgetList from 'components/menu/ActionMenuWidgetList';
import ProcessCanvasFileList from 'components/menu/ProcessCanvasFileList';
import ActionMenuCopilot from 'components/menu/ActionMenuCopilot';
import MetaPfdWidgetList from 'components/menu/MetaPfdWidgetList';
import styled from 'styled-components';
import ManagementList from 'components/menu/ManagementList';
import useScrollLoader from 'hooks/useScrollLoader';
import ScrollLoader from 'components/common/ScrollLoader';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 60px;
  z-index: 10; // 가장 위에 있어야함
  width: 0;
  height: 100%;
  color: #ffffff;
  transition: ${({theme}) => theme.action.menu.transition};
  box-shadow:
    2px 0 4px #00000040,
    4px 0 16px #00000040;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  background-color: #2f2f2f;
  scroll-behavior: smooth;

  &.mpfd-files {
    background-color: #1c1628;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #555;
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
  }

  &.active {
    width: ${({theme}) => theme.action.menu.width}px;
    display: flex;
    flex-direction: column;
  }
`;

const Scroller = styled.div`
  flex: 1;
  width: ${(props) => props.theme.action.menu.width}px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

type IProps = {
  appMenu?: IAppMenu;
};

function ActionMenu({appMenu}: IProps): ReactElement {
  const bodyRef = useRef<HTMLDivElement>(null);
  const [isShowScroll] = useScrollLoader(bodyRef);

  const scrollToBottom = () => {
    setTimeout(() => {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }, 200);
  };

  return (
    <Container ref={bodyRef} className={classNames('action-menu', appMenu?.id, {active: appMenu})}>
      <Scroller>
        {appMenu?.id === 'process-canvas' && <ProcessCanvasFileList scrollToBottom={scrollToBottom} />}
        {appMenu?.id === 'mpfd' && <MetaPfdWidgetList />}
        {appMenu?.id === 'widget' && <ActionMenuWidgetList />}
        {appMenu?.id === 'copilot' && <ActionMenuCopilot />}
        {appMenu?.id === 'mpfd-files' && <MetaPfdFileList scrollToBottom={scrollToBottom} />}
        {appMenu?.id === 'manage' && <ManagementList />}
      </Scroller>
      {isShowScroll && <ScrollLoader />}
    </Container>
  );
}

export default ActionMenu;
