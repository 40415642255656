import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {NodeProps, useReactFlow} from 'reactflow';
import {IPythonEditorLogWidgetData, IWidgetNodeData} from 'components/pc/types';
import React, {ChangeEvent, MouseEvent, useContext, useEffect, useState} from 'react';
import {IPythonEditorSubject, IWidgetSubject, LocalDatabaseContext} from 'api/LocalDatabaseProvider';
import {faFileLines} from '@fortawesome/pro-thin-svg-icons';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {SelectForm} from 'components/forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import {getWidgetTitle} from 'utils/processCanvas-functions';

const LogContainer = styled.div`
  height: 100%;
`;
const LogItem = styled.div`
  display: flex;
  gap: 5px;
  > span:first-child {
    min-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > span:last-child {
    flex-grow: 1;
  }
`;
const LogHeader = styled.div`
  height: 29px;
  display: flex;
  justify-content: center;
  background-color: #e8e8e8;
  font-size: 15px;
  font-weight: 400;
`;
const LogBody = styled.div`
  height: calc(100% - 29px);
  background-color: #f2f2f2;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 10px;
  box-sizing: border-box;
`;

const PythonLogActionPanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  > span {
    display: flex;
    font-size: 17px;
    justify-content: center;
    align-items: center;
    color: #575660;
  }
  > div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .common-select-form {
    padding: 5px 10px;
  }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #888;

  &:hover {
    background-color: rgba(213, 225, 243, 0.7);
  }
`;

const logLevel = ['low', 'medium'];
type logLevelTypes = (typeof logLevel)[number];

enum logLevelEnum {
  low = 0,
  medium = 1,
  high = 2
}

function PythonEditorLogWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>) {
  const {contentInfo} = useContext(LocalDatabaseContext);
  const [connectedPythonEditorSubjectInfo, setConnectedPythonEditorSubjectInfo] =
    useState<IWidgetSubject<IPythonEditorSubject>>();
  const [level, setLevel] = useState<logLevelTypes>('low');

  useEffect(() => {
    const targetId = (data.metaData as IPythonEditorLogWidgetData).targetPythonEditorId;
    const widgetSubjectInfo = contentInfo?.[targetId];
    if (widgetSubjectInfo?.type === 'PythonEditorWidget') {
      setConnectedPythonEditorSubjectInfo(widgetSubjectInfo as IWidgetSubject<IPythonEditorSubject>);
    }
  }, [contentInfo]);

  const getFormattedTimestamp = (timestamp: number) => {
    return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
  };

  const onChange = (e: ChangeEvent) => {
    const {value} = e.target as HTMLInputElement;
    setLevel(value);
  };

  const deleteLogAll = (e: MouseEvent) => {
    e.stopPropagation();
    connectedPythonEditorSubjectInfo?.ref?.current?.clearLogHistory();
  };
  const options = logLevel.map((item) => ({value: item, label: item}));

  const title = data.title === 'Python Editor' || data.title === '' ? 'Untitled' : data.title;

  return (
    <WidgetContainer {...rest} data={data} type="PythonEditorLogWidget">
      <WidgetHeader
        {...rest}
        type="PythonEditorLogWidget"
        icon={faFileLines}
        id={id}
        hasMinimized={false}
        title={getWidgetTitle({titleData: title, data})}
        suffix="- Python Editor Log"
        onClose={() => connectedPythonEditorSubjectInfo?.ref?.current?.afterRemovePythonLogWidget()}
      />
      <WidgetActionPanel>
        <PythonLogActionPanel>
          <div>
            <span>Log level</span>
            <SelectForm
              name="logLevel"
              className="logLevel"
              onChange={onChange}
              options={options}
              value={level}
              width={120}
              height={35}
            />
          </div>
          <div>
            <IconWrapper onClick={(e) => deleteLogAll(e)}>
              <FontAwesomeIcon icon={faTrash} />
            </IconWrapper>
          </div>
        </PythonLogActionPanel>
      </WidgetActionPanel>
      <WidgetBody actionMenuHeight={84}>
        <LogContainer>
          <LogHeader>Log</LogHeader>
          <LogBody className="thin-scrollbar">
            {connectedPythonEditorSubjectInfo?.ref?.current?.pythonEditorWidgetLogHistory?.map((item) => (
              <LogItem key={item.timestamp}>
                <span>{getFormattedTimestamp(item.timestamp)}</span>
                <span>
                  {item.msg}
                  <br />
                  {logLevelEnum[level] > 0 &&
                    item?.variableInfo &&
                    Object.entries(item?.variableInfo).map(([key, value]) => {
                      if (key === 'outflow' && logLevelEnum[level] > 1) {
                        return <></>;
                      }
                      return (
                        <>
                          <span>
                            {key} : <pre>{JSON.stringify(value)}</pre>
                          </span>
                        </>
                      );
                    })}
                  {logLevelEnum[level] > 1 &&
                    item?.apiRes &&
                    Object.entries(item?.apiRes).map(([key, value]) => {
                      if (!value) {
                        return <></>;
                      }
                      return (
                        <>
                          <span>
                            {key} : <pre>{value}</pre>
                          </span>
                        </>
                      );
                    })}
                </span>
              </LogItem>
            ))}
          </LogBody>
        </LogContainer>
      </WidgetBody>
    </WidgetContainer>
  );
}

export default PythonEditorLogWidget;
