import {ChangeEvent, ReactElement, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, TextForm} from 'components/forms';
import styled from 'styled-components';
import {IModelRunnerProject} from 'components/pc/widgets/modelRunner/types';

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 400px;
`;

type IProps = {
  project: IModelRunnerProject;
  onClose(): void;
  onConfirm(project: IModelRunnerProject): void;
};
function CreateProjectModal({project, onClose, onConfirm}: IProps): ReactElement {
  const [projectName, setProjectName] = useState(project.projectName);

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;
    setProjectName(value);
  };

  const onClickConfirm = (): void => {
    onConfirm({...project, projectName});
  };

  return (
    <ModalWrapper type="inner">
      <ModalHeader onClose={onClose}>Create New User Project</ModalHeader>
      <ModalBody>
        <Content>
          <TextForm placeholder="Project Name" autoFocus value={projectName} onChange={onChange} />
        </Content>
      </ModalBody>
      <ModalFooter>
        <Button width={120} onClick={onClickConfirm}>
          Create
        </Button>
        <Button variant="cancel" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default CreateProjectModal;
