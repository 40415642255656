import dayjs from 'dayjs';
import {Node} from 'reactflow';

/**
 * random 으로 key 생성
 * @returns string
 */
export const getUniqueKey = (): string => {
  return `key_${Math.round(Math.random() * 100000000)}_${new Date().getTime()}`;
};

/**
 * Camel-Case, Pascal-Case 를 Kebab-Case 로 변환
 * @param str
 */
export const toKebabCase = (str: string): string => {
  return str?.replace(/([a-z])([A-Z])/g, '$1-$2')?.toLowerCase();
};

export const toCamelCase = <T>(obj: T): Record<string, any> => {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1); // 첫 글자를 소문자로 변경
      acc[camelCaseKey] = value;
      return acc;
    },
    {} as Record<string, any>
  );
};

export const toDateFormat = (
  dataTime: string | number,
  format: 'YYYY-MM-DD HH:mm:ss' | 'YYYY-MM-DD' | 'HH:mm:ss'
): string => {
  if (typeof dataTime === 'string') {
    return dayjs(dataTime).format(format);
  } else if (typeof dataTime === 'number') {
    const val = parseInt(String(dataTime));
    return dayjs(val).format(format);
  }
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getZIndex = (nodes: Node[]): number => {
  const zIndexArray = nodes.map((node) => node.zIndex).sort((a, b) => a - b);
  const nodeCount = zIndexArray.length;
  let zIndex = 0;
  if (nodeCount > 0) {
    zIndex = zIndexArray[zIndexArray.length - 1] + 1;
  }
  return zIndex;
};

/**
 * Number[], String[] 가 같은지 비교
 */
type ValidType = number | string;
export const isNumberOrStringArraySame = <T extends ValidType>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (const item of arr1) {
    if (!arr2.includes(item) || arr1.findIndex((i) => i === item) !== arr2.findIndex((i) => i === item)) {
      return false;
    }
  }

  return true;
};
