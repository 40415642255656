import {useContext, useEffect, useState} from 'react';
import {DataContext} from 'api/DataProvider';
import useApi from 'api/useApi';
import {IApiReturnBasic} from 'api/data-types';

export type IPythonKernel = {
  id: string;
  name: string;
  environment: string;
  public: boolean;
  user_oid: string;
};

type IReturn = [
  string[],
  string,
  (envName: string) => void,
  () => void,
  IPythonKernel[],
  IPythonKernel,
  (kernelName?: string) => Promise<IPythonKernel>,
  (kernelName: string) => void,
  (reCreate?: boolean) => Promise<IPythonKernel>
];

function usePythonEnv(): IReturn {
  const {pythonEnvList, getPythonEnvList, pythonKernelList, getPythonKernelList} = useContext(DataContext);
  const [selectedEnv, setSelectedEnv] = useState<string>();
  const [selectedKernel, setSelectedKernel] = useState<IPythonKernel>(null);

  const api = useApi();

  useEffect(() => {
    if (pythonEnvList?.length === 0) {
      getPythonEnvList();
    } else {
      const [firstEnv] = pythonEnvList;
      setSelectedEnv(selectedEnv || firstEnv); // 없으면 undefined 할당됨;
    }
  }, [pythonEnvList, selectedEnv, pythonKernelList]);

  useEffect(() => {
    if (!selectedEnv) return;
    if (pythonKernelList?.length === 0) {
      getPythonKernelList().then((res) => {
        if (res?.length === 0) createKernel();
      });
    } else {
      const [firstKernel] = pythonKernelList;
      setSelectedKernel(firstKernel);
    }
  }, [selectedEnv, pythonKernelList]);

  const reload = (): void => {
    getPythonEnvList();
  };

  const changeEnv = (envName: string): void => {
    // todo: 해당 env 가 없으면 모달로 알려줘야 할 것 같음
    setSelectedEnv(pythonEnvList?.includes(envName) ? envName : undefined);
  };

  const createKernel = async (kernel_name?: string) => {
    if (!selectedEnv) return;
    const response = await api.post<IApiReturnBasic>('/python_editor/create_kernel', {
      env_name: selectedEnv,
      kernel_name
    });
    if (response.success) {
      const d = response.data as IPythonKernel;
      setSelectedKernel(d);
      await getPythonKernelList();
      return d;
    }
  };

  const changeKernel = (kernelId: string) => {
    const kernel = pythonKernelList.find((kernel) => kernel.id === kernelId);
    if (kernel) setSelectedKernel(kernel);
  };

  const deleteKernel = async (reCreate = false) => {
    if (!selectedKernel) return;
    const response = await api.post<IApiReturnBasic>('/python_editor/delete_kernel', {
      id: selectedKernel.id
    });
    if (response.success) {
      await getPythonKernelList();
      if (reCreate) {
        return await createKernel();
      }
    }
  };

  return [
    pythonEnvList,
    selectedEnv,
    changeEnv,
    reload,
    pythonKernelList,
    selectedKernel,
    createKernel,
    changeKernel,
    deleteKernel
  ];
}

export default usePythonEnv;
