import {ReactElement} from 'react';
import styled from 'styled-components';
import ServerItem from 'components/pc/widgets/modelRunner/initialize-modal-parts/ServerItem';
import {IModelRunnerServer} from 'components/pc/widgets/modelRunner/types';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  max-height: 400px;
  overflow-y: auto;
  gap: 16px;
`;

const customServer = {
  name: 'custom server',
  ip: '192.168.219.100',
  port: 7639,
  custom: true
};

type IProps = {
  serverList: IModelRunnerServer[];
  onSelect({server}: {server: IModelRunnerServer}): void;
};

function ServerItemList({serverList, onSelect}: IProps): ReactElement {
  return (
    <Container className="thin-scrollbar">
      {serverList?.map((server) => (
        <ServerItem key={server.name} title={server.name} onClick={() => onSelect({server: server})} />
      ))}
      <ServerItem title="custom server" onClick={() => onSelect({server: customServer})} />
    </Container>
  );
}

export default ServerItemList;
