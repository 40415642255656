import {ChangeEvent, Dispatch, MouseEvent, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CheckboxForm, SelectForm, TextForm} from 'components/forms';
import {faPause, faPlay, faWindowRestore} from '@fortawesome/pro-light-svg-icons';
import {IDatasheetSubject, IWidgetSubject} from 'api/LocalDatabaseProvider';
import classNames from 'classnames';
import GroupDivider from 'components/forms/GroupDivider';
import Tooltip from 'components/common/Tooltip';
import {IModelRunnerLoader} from 'components/pc/widgets/modelRunner/useModelRunnerLoader';

type styleProps = {
  $iconMode?: boolean;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  padding: 10px;
  box-sizing: border-box;

  &.on {
    flex-direction: column;
    gap: 10px;
  }
  &.off {
    align-items: center;
  }
`;

const Section = styled.div<styleProps>`
  height: 38px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-of-type(5) {
    border: none;
  }
  &:last-of-type {
    flex: 1;
    border: none;
    justify-content: space-between;
  }
  .checkbox-form {
    //min-width: ${(props) => (props.$iconMode ? 71 : 170)}px;
    label {
      font-size: 17px;
    }
  }
  .interval-value {
    width: 44px;
    height: 40px;
  }
  .interval-unit {
    width: 120px;
    height: 40px;
  }
  .environment {
    width: 140px;
    height: 40px;
  }

  &.on {
    height: 35px;

    &:last-of-type {
      flex: 0;
    }
    &.bottom {
      padding-top: 10px;
      border-top: 1px solid #acbcc0;
    }
  }
`;
const StyledSelectForm = styled(SelectForm)`
  margin-right: 10px;
`;

const PlayButtonWrap = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &.selected {
    background-color: #154183;
  }

  &:hover {
    background-color: rgba(213, 225, 243, 0.7);
  }

  &.enabled {
    color: ${({theme}) => theme.color.running};
    cursor: pointer;
  }

  &.disabled {
    opacity: 30%;
    cursor: default;
    color: ${({theme}) => theme.color.dark};
  }
`;

const BtnWrap = styled.div`
  &.info-btn {
    margin-top: 3px;
  }
  display: flex;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: -2px;
  border-radius: 3px;
  padding: 5px;
  color: #888;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1f1f1f;
    background-color: #d5e1f3;
  }

  &.selected {
    color: #1f1f1f;
    background-color: #d5e1f3;
  }
`;

const IntervalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 20px;

  &.on {
    margin-left: 0;
  }
`;

const LastSectionRight = styled.div`
  display: flex;
  margin-left: auto;
  gap: 5px;

  &.off {
    margin-top: 3px;
  }
`;

const intervalUnitOption = [
  {label: 'seconds', value: 1000},
  {label: 'minutes', value: 60 * 1000},
  {label: 'hours', value: 60 * 60 * 1000}
];

type IProps = {
  loader: IModelRunnerLoader;
  iconMode: boolean;
  isRunState: [boolean, Dispatch<SetStateAction<boolean>>];
  projectNameList: string[];
  connectedDatasheetSubjectInfo?: IWidgetSubject<IDatasheetSubject>;
  onClickIconModeIcon(): void;
  onToggleRun(bool: boolean): void;
};
function ModelRunnerActionPanel({
  loader,
  iconMode,
  isRunState,
  projectNameList,
  connectedDatasheetSubjectInfo,
  onClickIconModeIcon,
  onToggleRun
}: IProps) {
  const {project, runConfig, updateRunConfig} = loader;
  const [isRun, setIsRun] = isRunState;

  const [projectName, setProjectName] = useState<string>('');
  // const runCfgRef = useRef(null);
  // runCfgRef.current = runCfg;

  const onChangeBooleanConfig = (key: string, value: boolean) => {
    updateRunConfig({[key]: value});

    switch (key) {
      case 'batchRun': {
        setTimeout(() => connectedDatasheetSubjectInfo?.ref?.current?.changeReadOnly?.(value), 0);
        break;
      }
    }
  };

  // todo projectList 재설정 기능 추가 필요 => onChange 내부에 projectName을 value로 받도록!
  const onChange = (e: ChangeEvent) => {
    const {name, value} = e.target as HTMLInputElement;
    const isNumber = !isNaN(Number(value)) && Number(value) > 0;
    switch (name) {
      case 'intervalUnit':
      case 'intervalValue': {
        updateRunConfig({[name]: isNumber ? Number(value) : null});
        break;
      }
      default:
        break;
    }
  };

  const modelStatus = loader.status(loader.seqInfo, 'key');

  const isDisabled =
    isRun || modelStatus === undefined || modelStatus.sts === 'Idle' || modelStatus.cmd === 'SAVE_MODEL'
      ? 'disabled'
      : 'enabled';

  const onClickRun = (e: MouseEvent) => {
    if (isDisabled !== 'disabled') {
      e.stopPropagation();
      const bool = !isRun;
      setIsRun(bool);
      onToggleRun(bool);
    }
  };

  const iconModeToggle = iconMode ? 'on' : 'off';

  const selectedProjectName = () => {
    if (project) {
      setTimeout(() => setProjectName(project?.projectName), 1500);
    }
    return projectName;
  };

  return (
    <Container className={iconModeToggle}>
      <Section className={iconModeToggle}>
        <PlayButtonWrap className={classNames(isDisabled)} onClick={onClickRun}>
          <FontAwesomeIcon icon={isRun ? faPause : faPlay} fontSize={24} />
        </PlayButtonWrap>
        <GroupDivider />
        <CheckboxForm
          name="autoRun"
          checked={runConfig?.autoRun}
          onChange={() => onChangeBooleanConfig('autoRun', !runConfig?.autoRun)}
          label={iconMode ? 'Auto' : 'Auto - run'}
          labelPosition="right"
          // disabled={true}
        />
      </Section>
      <Section className={classNames(iconModeToggle, 'bottom')} $iconMode={iconMode}>
        <IntervalContainer className={iconModeToggle}>
          <TextForm
            name="intervalValue"
            className="interval-value"
            onChange={onChange}
            value={runConfig?.intervalValue}
            // disabled={true}
            width={50}
            height={35}
            style={{marginRight: 10, textAlign: 'center'}}
          />
          <SelectForm
            name="intervalUnit"
            className="interval-unit"
            onChange={onChange}
            options={intervalUnitOption}
            value={runConfig?.intervalUnit}
            // disabled={true}
            width={!iconMode ? 120 : 124}
            height={35}
          />
          {!iconMode && 'interval'}
        </IntervalContainer>
        {!iconMode && <GroupDivider />}
        {/*<CheckboxForm
          name="batchRun"
          checked={runConfig?.batchRun}
          onChange={() => onChangeBooleanConfig('batchRun', !runCfg?.batchRun)}
          label={iconMode ? 'Batch' : 'Use for batch run'}
          labelPosition="right"
          disabled={!Boolean(connectedDatasheetSubjectInfo)}
        />*/}
        {/*{!iconMode && <GroupDivider />}*/}
        {/*<div style={{width: !iconMode ? '30px' : '2px'}}></div>*/}
        {/*<StyledSelectForm*/}
        {/*  name="environment"*/}
        {/*  className="environment"*/}
        {/*  placeholder="Select project"*/}
        {/*  onChange={onChange}*/}
        {/*  options={projectNameList?.map((item) => ({label: item, value: item}))}*/}
        {/*  value={selectedProjectName()}*/}
        {/*  width={!iconMode ? 140 : 104}*/}
        {/*  height={35}*/}
        {/*/>*/}
        {iconMode && <div style={{width: '16px'}}></div>}
        <LastSectionRight className={iconModeToggle}>
          <Tooltip content={!iconMode ? 'Icon Mode On' : 'Icon Mode Off'} place="left">
            <BtnWrap
              className={classNames(iconMode && 'selected', iconModeToggle)}
              onClick={() => onClickIconModeIcon()}
            >
              <FontAwesomeIcon icon={faWindowRestore} fontSize={18} />
            </BtnWrap>
          </Tooltip>
        </LastSectionRight>
      </Section>
    </Container>
  );
}

export default ModelRunnerActionPanel;
