import {MouseEvent, PropsWithChildren, ReactElement} from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import {BasicSpinner} from 'components/common';
import styled from 'styled-components';
import {Button} from 'components/forms';

// Action Panel 내부의 Select Nodes 버튼, 라벨
const Container = styled(Button)`
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  transition: background-color 1s;

  .fa-tags {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &.loading {
    background-color: #cdd5dc;
    color: #ffffff;
    cursor: default;
    transition: background-color 0s;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.3) 60%,
        rgba(255, 255, 255, 0.3) 90%,
        rgba(255, 255, 255, 0.1) 92%,
        rgba(255, 255, 255, 0)
      );
      animation: shimmer 1s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }

    .fa-tags {
      opacity: 0;
    }
  }
`;
const SelectNodeButtonLabel = styled.span`
  margin-left: 6px;
`;

type IProps = PropsWithChildren & {
  width?: number | string;
  height?: number | string;
  isLoading?: boolean;
  onClick(e: MouseEvent): void;
};

function NodesSelectButton({children, width = 'auto', height = 'auto', isLoading, onClick}: IProps): ReactElement {
  return (
    <Container
      variant="dark"
      width={width}
      height={height}
      className={classNames({loading: isLoading})}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faTags} color="#aaa" size="lg" />
      <BasicSpinner isShow={isLoading} margin="0" size="sm" position="center-left" type="overlay" />
      <SelectNodeButtonLabel>{children || 'Select Nodes'}</SelectNodeButtonLabel>
    </Container>
  );
}

export default NodesSelectButton;
