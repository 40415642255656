import {IModelRunnerRunConfig} from 'components/pc/widgets/modelRunner/types';

export const modelRunnerDefaultStateValue = {
  variableTableState: [],
  runConfigState: {
    autoRun: false,
    batchRun: false,
    intervalUnit: 1000,
    intervalValue: 1,
    environment: ''
  } as IModelRunnerRunConfig
};
