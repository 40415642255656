import React, {
  DragEvent,
  DragEventHandler,
  MouseEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  addEdge,
  applyEdgeChanges,
  Background,
  BackgroundVariant,
  Connection,
  EdgeChange,
  MarkerType,
  Node,
  NodeChange,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';
import 'components/pc/assets/styles.scss';
import {AppContainer} from 'components/layout';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import Toolbox from 'components/pc/common/Toolbox';
import ProcessCanvasMiniMap from 'components/pc/common/ProcessCanvasMiniMap';
import ShapeDataBindingModal from 'components/pc/common/shapes/ShapeDataBindingModal';
import DevTools from 'components/pc/common/devTool/DevTool';
import SaveLoadingModal from 'components/menu/pulldown/SaveLoadingModal';
import ProcessCanvasSaveAsModal from 'components/menu/pulldown/ProcessCanvasSaveAsModal';
import ProcessCanvasCloseWarningModal from 'components/menu/pulldown/ProcessCanvasCloseWarningModal';
import PullDownMenu from 'components/menu/pulldown/PullDownMenu';
import ProcessCanvasPullDownMenu from 'components/menu/pulldown/ProcessCanvasPullDownMenu';
import FileInfoDisplay from 'components/menu/pulldown/FileInfoDisplay';
import ProcessCanvasAppGuide from 'components/pc/ProcessCanvasAppGuide';
import ProcessCanvasDefaultBrowserPermissionTooltip from 'components/pc/common/ProcessCanvasDefaultBrowserPermissionTooltip';
import HelperLines from 'components/pc/helper-lines/HelperLines';
import {useParams} from 'react-router-dom';
import {useCanvasEffect} from 'hooks/useProcessCanvasEffect';
import {useCanvasHandlers} from 'hooks/useProcessCanvasHandler';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import useProcessCanvasMenuCheckUsable from 'components/menu/pulldown/useProcessCanvasMenuCheckUsable';
import Taskbar from 'components/pc/taskbar/Taskbar';
import ProcessCanvasImportModal from 'components/menu/pulldown/ProcessCanvasImportModal';
import ProcessCanvasExportModal from 'components/menu/pulldown/ProcessCanvasExportModal';
import ProcessCanvasTransferOwnershipModal from 'components/menu/pulldown/ProcessCanvasTransferOwnershipModal';
import ProcessCanvasChangeAccessibilityModal from 'components/menu/pulldown/ProcessCanvasChangeAccessibilityModal';
import ProcessCanvasZoomScaleViewer from 'components/pc/common/ProcessCanvasZoomScaleViewer';
import DockingLayout from 'components/pc/docking/DockingLayout';
import ReactFlowStage from 'components/pc/docking/ReactFlowStage';
import {IPosition} from 'components/common/types';
import {DataContext} from 'api/DataProvider';
import {Edge} from '@reactflow/core/dist/umd/types/edges';
import {LocalDatabaseContext} from 'api/LocalDatabaseProvider';
import ResizeHandle from 'components/common/resizer/ResizeHandle';
import useDraggable from 'utils/useDraggable';
import {PullDownFunctions, widgetList} from 'components/menu/constants';
import {widgetCollection} from 'components/pc/widgets/widget-constants';
import {OnConnect} from '@reactflow/core/dist/esm/types/general';
import {pcFunc, pcHooks} from 'utils/processCanvas-functions';
import {IReactFlowLayout} from 'api/data-types';
import {NodeDragHandler} from '@reactflow/core/dist/esm/types/nodes';
import {IDragWidgetActionMenu, WidgetTypes} from 'components/menu/types';
import DockingPlace from 'components/pc/docking/DockablePlace';
import {getUniqueKey} from 'utils/commons';
import ProcessCanvasAllowCopyModal from 'components/menu/pulldown/ProcessCanvasAllowCopyModal';
import {IControlPointData, EditableEdge} from 'components/pc/widgets/parts/editableEdge';
import {useAppStore} from 'components/pc/connection-store';
import {Algorithm} from 'components/pc/widgets/parts/editableEdge/constants';
import {ConnectionLine} from 'components/pc/widgets/parts/ConnectionLine';
import CustomEdge from 'components/pc/widgets/parts/CustomEdgetDeleteBtn';
import {IContextMenuItem} from 'components/common/context-menu/ContextMenu';

function ProcessCanvas(): ReactElement {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [changes, setChanges] = useState<NodeChange[]>([]);
  const [isNodeMouseEnter, setIsNodeMouseEnter] = useState<boolean>(false);
  const {
    file,
    hasMiniMapState,
    isFreezeState,
    isFileOwner,
    hasSmartAlignState,
    hasZoomScaleState,
    closeModalState,
    saveLoadingModalState,
    saveState,
    saveAsModalState,
    dataSettingModalState,
    importModalState,
    exportModalState,
    allowCopyModalState,
    ownershipModalState,
    accessibilityModalState,
    canvasEnteredState,
    panningState,
    enteredSpacebarState,
    activeToolState,
    onCanvasChange,
    createProcessCanvasContextMenu,
    dockState
  } = useContext(ProcessCanvasContext);
  const {afterEdgesChange} = useContext(LocalDatabaseContext);
  const [closeModalElement] = closeModalState;
  const [saveLoadingModalElement] = saveLoadingModalState;
  const [saveLoadingState] = saveState;
  const [saveAsModalElement] = saveAsModalState;
  const [importModalElement] = importModalState;
  const [exportModalElement] = exportModalState;
  const [allowCopyModalElement] = allowCopyModalState;
  const [ownershipModalElement] = ownershipModalState;
  const [accessibilityModalElement] = accessibilityModalState;
  const [dataSettingModalElement] = dataSettingModalState;
  const boundaryRef = useRef(null);
  const {id} = useParams();
  const [hasMinMap] = hasMiniMapState;
  const [hasZoomScale] = hasZoomScaleState;
  const [isFreeze] = isFreezeState;
  const [hasSmartGuide] = hasSmartAlignState;
  const [panning] = panningState;
  const [enteredSpacebar] = enteredSpacebarState;
  const [canvasEntered, setCanvasEntered] = canvasEnteredState;
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;
  const panDistance = globalSettings.panDistance;
  const [dockedIdList] = dockState;
  const {
    onDragOver,
    onNodeClick,
    onNodeChangeClosure,
    // onConnect,
    horizontalHelperLine,
    verticalHelperLine
  } = useCanvasHandlers(nodes, edges, setNodes, setEdges, setChanges);
  const reactFlow = useReactFlow();
  useCanvasEffect(nodes, edges);
  const {checkPaste} = useProcessCanvasMenuCheckUsable();
  const {dock} = useProcessCanvasCommand();

  const nodeTypes = useMemo(() => widgetCollection, []);

  const edgeTypes = useMemo(
    () => ({
      /**
       * MIGRATION-9
       * 호환성을 유지를 위해 이전 edge 추가 - 2024-10-17
       */
      buttonEdge: CustomEdge,
      editableEdge: EditableEdge
    }),
    []
  );

  if (process.env.REACT_APP_HIDE_IFRAME_WARNING) {
    const style = document.createElement('style');
    style.innerHTML = 'iframe#webpack-dev-server-client-overlay { display: none !important; }';
    document.head.appendChild(style);
  }

  const {paste, addWidget, pan} = useProcessCanvasCommand();

  const onContextMenuPane = (event: MouseEvent) => {
    event.preventDefault();

    checkPaste().then((isNotExistCopiedWidget) => {
      const activeWidgetList = widgetList
        .filter((item) => !item?.inactive)
        .map((widgetInfo) => ({
          label: widgetInfo.title,
          value: widgetInfo.title,
          icon: widgetInfo.icon,
          disabled: isFreeze,
          callback: () => {
            addWidget(widgetInfo.id as PullDownFunctions, event.pageX, event.pageY);
          }
        }));

      const list: IContextMenuItem[] = [
        {
          label: 'Paste Widget',
          value: 'paste-widget',
          callback: () => {
            paste(event.pageX, event.pageY);
          },
          disabled: isNotExistCopiedWidget
        },
        {
          label: 'Add Widget',
          value: 'add-widget',
          children: activeWidgetList
        },
        {
          label: 'Comment Here',
          value: 'comment-here',
          disabled: true
        }
      ];
      createProcessCanvasContextMenu({event, list});
    });
  };

  const onMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    setCanvasEntered(true);
  };

  const onMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
    setCanvasEntered(false);
  };

  const onNodesDelete = () => {
    onCanvasChange();
  };

  const onEdgesDelete = (edges: Edge[]) => {
    onCanvasChange();
    afterEdgesChange(nodes, edges);
  };

  const [zoomStartPos, setZoomStartPos] = useState<IPosition>(null);

  const onSelectionStart = (event: React.MouseEvent) => {
    if (activeToolState[0] === 'zoomIn' || activeToolState[0] === 'zoomOut') {
      setZoomStartPos({x: event.clientX, y: event.clientY});
      setNodes((nodes) => nodes.map((item) => ({...item, selectable: false})));
    }
  };

  const zoomInViaPoint = (event: React.MouseEvent) => {
    const direction = activeToolState[0] === 'zoomIn' ? 1 : activeToolState[0] === 'zoomOut' ? -1 : 0;
    if (direction === 0) return;

    const vp = reactFlow.getViewport();
    let newZoom = vp.zoom + direction * 0.2;
    if (newZoom < 0.2) newZoom = 0.2;
    if (newZoom > 4) newZoom = 4;

    /**
     * 현재 화면의 Dom 경계선에서 상대적인 마우스 위치를 계산
     */
    const dom = event.currentTarget.getBoundingClientRect();
    const [mx, my] = [event.clientX - dom.left, event.clientY - dom.top];

    /**
     * 현재 pane 상대적 mouse 위치 계산
     */
    const worldMouseX = (mx - vp.x) / vp.zoom;
    const worldMouseY = (my - vp.y) / vp.zoom;
    const newWorldMouseX = worldMouseX * newZoom;
    const newWorldMouseY = worldMouseY * newZoom;
    const newX = mx - newWorldMouseX;
    const newY = my - newWorldMouseY;
    /**
     * newX =  mx - (mx - vp.x) * (newZoom / vp.zoom)
     * (화면내의 x 좌표) - (rf 화면 내의 x 좌표) * (zoomDiff)
     * => new vp.x
     */
    reactFlow.setViewport({x: newX, y: newY, zoom: newZoom});
  };

  const onSelectionEnd = (event: React.MouseEvent) => {
    setNodes((nodes) => nodes.map((item) => ({...item, selectable: true})));
    if (!zoomStartPos) {
      if (activeToolState[0] === 'zoomIn') {
        zoomInViaPoint(event);
      }
      if (activeToolState[0] === 'zoomOut') {
        zoomInViaPoint(event);
      }
      return;
    }
    if (activeToolState[0] === 'zoomIn' || activeToolState[0] === 'zoomOut') {
      const startPosition = reactFlow.screenToFlowPosition(zoomStartPos);
      const endPosition = reactFlow.screenToFlowPosition({x: event.clientX, y: event.clientY});
      const w = endPosition.x - startPosition.x;
      const h = endPosition.y - startPosition.y;
      setZoomStartPos(null);
      if (Math.pow(w, 2) + Math.pow(h, 2) < 300) {
        if (activeToolState[0] === 'zoomIn') {
          zoomInViaPoint(event);
        }
        if (activeToolState[0] === 'zoomOut') {
          zoomInViaPoint(event);
        }
      } else {
        reactFlow.fitBounds(
          {
            width: w,
            height: h,
            x: startPosition.x,
            y: startPosition.y
          },
          {duration: 500}
        );
      }
    }
  };

  // arrow keyboard로 pan하는 기능 관련 코드
  // FIXME: canvasEntered 대신 local하게 canvas를 보고 있음을 저장하는 state 만들어서 사용
  const onClickCanvas = (event: React.MouseEvent) => {
    const clickedElement = event.target as HTMLElement;
    if (typeof clickedElement?.className === 'string' && clickedElement?.className?.includes('react-flow__pane')) {
      setIsNodeMouseEnter(false);
      return;
    } else {
      setIsNodeMouseEnter(true);
      return;
    }
  };

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isNodeMouseEnter) return;
      if (!canvasEntered) return;
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        pan(0, -panDistance);
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        pan(0, panDistance);
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault();
        pan(-panDistance, 0);
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        pan(panDistance, 0);
      }
    },
    [isNodeMouseEnter, canvasEntered, panDistance]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown, isNodeMouseEnter, canvasEntered]);

  useEffect(() => {
    const element = boundaryRef.current;

    if (element) {
      element.addEventListener('click', onClickCanvas);
    }

    return () => {
      if (element) {
        element.removeEventListener('click', onClickCanvas);
      }
    };
  }, [boundaryRef.current, onClickCanvas, isNodeMouseEnter]);
  // arrow keyboard로 pan하는 기능 관련 코드

  const onEdgesChangeClosure = (changes: EdgeChange[]) => {
    setEdges(function (edges) {
      let edgesTemp = edges?.length > 0 ? [...edges] : edges;
      edgesTemp = applyEdgeChanges(changes, edges);
      return edgesTemp;
    });
  };

  const ref = useRef(null);
  const resizerRef = useRef(null);
  const defaultPadding = {top: 0, right: 200, bottom: 0, left: 400};
  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth);
  const [position, onMouseDown] = useDraggable(ref, resizerRef, {x: 1000, y: 0}, defaultPadding);

  useEffect(() => {
    if (ref.current) {
      setCanvasWidth(ref.current.clientWidth);
    }
  }, [ref]);

  const onConnect: OnConnect = useCallback(
    (connection) => {
      const {connectionLinePath} = useAppStore.getState();

      const targetNode = reactFlow.getNode(connection.target);
      const sourceNode = reactFlow.getNode(connection.source);

      if (connection.targetHandle === 'widget-edge-target' && connection.sourceHandle === 'widget-edge-source') {
        //todo widget mapping 정리 필요 추후에 작업
        // MetaPfdWidget을 DataSheetWidget만 연결 가능하도록 조건 추가
        if (targetNode.type === 'MetaPfdWidget') {
          if (sourceNode.type !== 'DatasheetLocalDbWidget') return;
        }
        if (sourceNode.type === 'MetaPfdWidget') {
          if (targetNode.type !== 'DatasheetLocalDbWidget') return;
        }
        reactFlow.setEdges((eds) => {
          const newEds = addEdge(
            {
              ...connection,
              id: `${Date.now()}-${connection.source}-${connection.target}`,
              type: 'editableEdge',
              data: {
                algorithm: Algorithm.CatmullRom,
                points: connectionLinePath.map(
                  (point, i) =>
                    ({
                      ...point,
                      id: window.crypto.randomUUID(),
                      prev: i === 0 ? undefined : connectionLinePath[i - 1],
                      active: true
                    }) as IControlPointData
                )
              },
              markerEnd: {
                type: MarkerType.Arrow,
                color: '#FF0072',
                width: 10,
                height: 10,
                strokeWidth: 2
              }
            },
            eds
          );
          afterEdgesChange(nodes, newEds);
          return newEds;
        });
      }

      if (connection.targetHandle.includes('port-edge-') && connection.sourceHandle.includes('port-edge-')) {
        const {target, source} = connection;
        const zIndexArr = nodes
          .filter((item) => item.id === target || item.id === source)
          .map((item) => item.zIndex)
          .sort();
        const edgeZIndex = zIndexArr?.[1] + 1 || 1;

        onCanvasChange();

        reactFlow.setEdges((eds) =>
          addEdge(
            {
              ...connection,
              id: `${Date.now()}-${connection.source}-${connection.target}`,
              type: 'editableEdge',
              zIndex: edgeZIndex,
              style: {zIndex: edgeZIndex},
              selected: true,
              data: {
                algorithm: Algorithm.Linear,
                points: connectionLinePath.map(
                  (point, i) =>
                    ({
                      ...point,
                      id: window.crypto.randomUUID(),
                      prev: i === 0 ? undefined : connectionLinePath[i - 1],
                      active: true
                    }) as IControlPointData
                )
              },
              markerEnd: {
                type: MarkerType.Arrow,
                color: '#0375ff',
                width: 10,
                height: 10,
                strokeWidth: 2
              }
            },
            eds
          )
        );
      }
    },
    [afterEdgesChange, nodes, onCanvasChange, reactFlow]
  );

  useEffect(() => {
    if (!file) {
      return;
    }
    const stateData = file?.stateData as IReactFlowLayout;
    if (stateData?.nodes) {
      /**
       *  [MIGRATION-2]
       *  PMM-452/change-name branch 'ExtendedSpreadsheetWidget' => 'DataSheetWidget' 로 변경함에 이어서 호환성 해결
       */
      const nodes = stateData?.nodes?.map((item) =>
        item?.type === 'ExtendedSpreadsheetWidget' ? {...item, type: 'DataSheetWidget'} : item
      ) as Node[];

      const edges = stateData?.edges as Edge[];
      reactFlow.setEdges(edges);
      reactFlow.setNodes(nodes);
      afterEdgesChange(nodes, edges);
    } else {
      const nodes = stateData as unknown;
      reactFlow.setNodes(nodes as Node[]);
    }
  }, [file?.stateData]);

  const dragNodePosition = useRef(null);
  const dragNodePositionFromRight = useRef(null);
  const dockingBoundaryXPosition = position?.x + 50; // 50 : draggable handle width
  const dockableWidgetTypes: WidgetTypes[] = [
    'HmbRevisionWidget',
    'TimeSeriesWidget',
    'DatasheetLocalDbWidget',
    'StickyNoteWidget'
  ];

  const onNodeDragStart: NodeDragHandler = (event: MouseEvent, node: Node) => {
    dragNodePosition.current = 0;
    dragNodePositionFromRight.current = 0;
    const t = event.target as HTMLElement;
    if (t?.tagName === 'BUTTON ' || t?.tagName === 'path' || t?.tagName === 'svg') {
      return;
    }
    const newNodes = pcHooks.onNodeClick(node, nodes);
    setNodes(newNodes);
    const newEdges = pcHooks.onNodeZIndexChangedEdgeZIndexCalc(newNodes, node, edges);
    setEdges(newEdges);
  };

  // docking layout 임시 비활성화
  const onNodeDragStop: NodeDragHandler = (event: MouseEvent, node: Node) => {
    if (dockedIdList.length === 0) {
      if (Boolean(dragNodePositionFromRight.current) && dragNodePositionFromRight.current < 260) {
        setNodes((nodes) =>
          nodes.map((item) =>
            item.id === node.id ? {...item, hidden: true, data: {...item.data, docked: true}} : item
          )
        );
        setTimeout(() => dock(true, [node.id]), 0);
      }
    } else {
      if (
        dragNodePosition.current > dockingBoundaryXPosition &&
        dockableWidgetTypes.includes(node.type as WidgetTypes)
      ) {
        setNodes((nodes) =>
          nodes.map((item) =>
            item.id === node.id ? {...item, hidden: true, data: {...item.data, docked: true}} : item
          )
        );
        setTimeout(() => dock(true, [node.id]), 0);
      }
    }

    dragNodePosition.current = 0;
    dragNodePositionFromRight.current = 0;
  };

  const onNodeDrag: NodeDragHandler = (event: MouseEvent, node: Node) => {
    const mouseEvent = event as MouseEvent & {offsetX: number; offsetY: number};
    if (dockableWidgetTypes.includes(node?.type as WidgetTypes)) {
      dragNodePosition.current = mouseEvent.pageX;
      dragNodePositionFromRight.current = window.innerWidth - mouseEvent.clientX;
    }
  };

  const onDrop: DragEventHandler = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    const position = reactFlow.screenToFlowPosition({x: evt.clientX, y: evt.clientY});
    const zoom = reactFlow.getZoom();
    if (isNaN(1 / zoom)) {
      return;
    }
    let newNode: Node;

    const dropItemFromShape = evt.dataTransfer.getData('application/reactflow');
    const dropItemFormWidgetMenu = evt.dataTransfer.getData('application/dataTransfer/widget');
    const dropItemFromDocked = evt.dataTransfer.getData('application/dataTransfer/widget/docked');

    if (dropItemFormWidgetMenu) {
      const widgetData = JSON.parse(dropItemFormWidgetMenu) as IDragWidgetActionMenu;
      const id = getUniqueKey();
      const {highestZIndex} = pcFunc.getZIndex(nodes);

      newNode = {
        id,
        type: widgetData.type,
        data: {icon: widgetData?.icon, title: widgetData?.title, mPfdFileData: widgetData?.metaPfd},
        style: widgetData?.style,
        position,
        zIndex: highestZIndex,
        selected: true
      };
    } else if (dropItemFromShape) {
      const {highestZIndex} = pcFunc.getZIndex(nodes);
      const height = dropItemFromShape === 'Text' ? 50 : 100;
      const style = {width: 100 / zoom, height: height / zoom};
      newNode = {
        id: getUniqueKey(),
        type: 'shape',
        position,
        style,
        selected: false,
        data: {
          type: dropItemFromShape,
          color: '#3F8AE2'
        },
        zIndex: highestZIndex + 1
      };
    } else if (dropItemFromDocked) {
      const id = JSON.parse(dropItemFromDocked) as string;
      reactFlow.setNodes((nodes) =>
        nodes.map((item) =>
          item.id === id ? {...item, position, hidden: false, data: {...item.data, docked: false}} : item
        )
      );
      dock(false, [id]);
    }

    if (newNode) {
      reactFlow.setNodes((nodes) => nodes.map((item) => ({...item, selected: false})));
      reactFlow.addNodes(newNode);
    }
  };

  return (
    <AppContainer type="process-canvas" isFreeze={isFreeze}>
      <PullDownMenu isFreeze={isFreeze}>
        <FileInfoDisplay
          file={file}
          type="process-canvas"
          isPrivate={file?.public && !isFileOwner}
          isFileOwner={isFileOwner}
        />
        <ProcessCanvasPullDownMenu />
      </PullDownMenu>
      {id ? (
        <ReactFlowStage ref={ref}>
          <ReactFlow
            className={activeToolState[0]}
            minZoom={0.2}
            maxZoom={4}
            edgesUpdatable={!isFreeze}
            edgesFocusable={!isFreeze}
            nodesDraggable={
              !isFreeze &&
              !enteredSpacebar &&
              !panning &&
              !(activeToolState[0] === 'zoomOut') &&
              !(activeToolState[0] === 'zoomIn')
            }
            nodesConnectable={!isFreeze && !(activeToolState[0] === 'zoomOut') && !(activeToolState[0] === 'zoomIn')}
            nodesFocusable={!isFreeze && !(activeToolState[0] === 'zoomOut') && !(activeToolState[0] === 'zoomIn')}
            elementsSelectable={!isFreeze || activeToolState[0] === 'zoomOut' || activeToolState[0] === 'zoomIn'}
            proOptions={{account: 'paid-pro', hideAttribution: true}}
            elevateNodesOnSelect={false}
            nodeTypes={nodeTypes}
            nodes={nodes}
            onNodesChange={onNodeChangeClosure}
            selectNodesOnDrag={false}
            ref={boundaryRef}
            edgeTypes={edgeTypes}
            edges={edges}
            onPaneContextMenu={onContextMenuPane}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={onNodeClick}
            onNodeContextMenu={onNodeClick}
            onNodeDragStart={onNodeDragStart}
            onNodeDrag={onNodeDrag}
            // onNodeDragStop={onNodeDragStop}
            deleteKeyCode={'Delete'}
            disableKeyboardA11y={true}
            selectionOnDrag
            panOnDrag={panning || [1]}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onNodesDelete={onNodesDelete}
            onSelectionStart={onSelectionStart}
            onSelectionEnd={onSelectionEnd}
            onConnect={onConnect}
            connectionLineComponent={ConnectionLine}
            onEdgesChange={onEdgesChange}
            // onEdgesChange={onEdgesChangeClosure}
            onEdgesDelete={onEdgesDelete}
            zoomOnDoubleClick={false}
            // autoSave={}
            autoPanOnNodeDrag={false}
          >
            {process.env.REACT_APP_USE_REACT_FLOW_DEBUG_MODE && <DevTools changes={changes} />}
            <Background color="black" variant={BackgroundVariant.Dots} gap={10} />
            {hasZoomScale && (
              <Panel position="bottom-right">
                <ProcessCanvasZoomScaleViewer hasMiniMap={hasMinMap} />
              </Panel>
            )}
            {hasMinMap && <ProcessCanvasMiniMap />}
            <Toolbox boundaryRef={boundaryRef} />
            {hasSmartGuide && <HelperLines horizontal={horizontalHelperLine} vertical={verticalHelperLine} />}
          </ReactFlow>
          {/*{nodes.filter((node) => node.data.docked).length > 0 && (
            <>
              <ResizeHandle ref={resizerRef} type="vertical" color="#CBD5E0" stroke={4} onMouseDown={onMouseDown} />
              <DockingLayout width={canvasWidth - position.x} />
            </>
          )}*/}
          {/*<DockingPlace
            width={canvasWidth - position.x}
            dragNodePositionFromRight={dragNodePositionFromRight}
            isVisible={dragNodePosition.current > dockingBoundaryXPosition}
          />*/}
        </ReactFlowStage>
      ) : (
        <ProcessCanvasAppGuide />
      )}
      {saveLoadingModalElement && (
        <SaveLoadingModal modalState={saveLoadingState} loadingState={saveLoadingModalState} />
      )}
      {saveAsModalElement && <ProcessCanvasSaveAsModal />}
      {closeModalElement && <ProcessCanvasCloseWarningModal />}
      {dataSettingModalElement && <ShapeDataBindingModal />}
      {importModalElement && <ProcessCanvasImportModal />}
      {exportModalElement && <ProcessCanvasExportModal />}
      {allowCopyModalElement && <ProcessCanvasAllowCopyModal />}
      {ownershipModalElement && <ProcessCanvasTransferOwnershipModal />}
      {accessibilityModalElement && <ProcessCanvasChangeAccessibilityModal />}
      <ProcessCanvasDefaultBrowserPermissionTooltip />
      {id && <Taskbar />}
    </AppContainer>
  );
}

export default ProcessCanvas;
