import React, {ReactElement, useContext} from 'react';
import styled from 'styled-components';
import {faChevronLeft} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  position: sticky;
  top: 0;
  padding-top: 12px;
  z-index: 1;
  height: 80px;

  .create-btn {
    margin-top: 5px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    backdrop-filter: blur(5px);
  }

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.2em;
    font-size: calc(1.75 * 1rem);
    font-weight: 200;
    color: #ffffff;
    padding: 0 1rem;
    margin: 0;

    small {
      margin-left: 5px;
      font-size: 0.7em;
      color: rgba(255, 255, 255, 0.28);
    }
  }
`;
const Descriptions = styled.div`
  height: 30px;
  margin-top: 6px;
  display: flex;
  padding: 0 20px;

  > h3 {
    font-size: calc(1 * 1rem);
    font-weight: 400;
    color: #cb7faa;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
    line-height: 1em;
    flex: 1;
  }
  //height: 50px;
`;
const MenuCloseBtn = styled.div`
  flex-shrink: 0;
  font-size: 20px;

  svg {
    cursor: pointer;
    color: #d0d0d0;
  }
`;

type IProps = {
  main: string;
  sub?: string;
  description: string;
  children?: ReactElement;
};

function ActionMenuHeader({children, main, sub, description}: IProps): ReactElement {
  const {remoteAppMenu, showActionMenu} = useContext(CommonContext);

  const onClickContainer = () => {
    if (remoteAppMenu) {
      showActionMenu(null);
    }
  };

  return (
    <Container>
      <h2>
        <span>
          {main}
          {sub && <small>{sub}</small>}
        </span>
        <MenuCloseBtn>
          <FontAwesomeIcon icon={faChevronLeft} size="lg" onClick={onClickContainer} />
        </MenuCloseBtn>
      </h2>
      <Descriptions>
        <h3>{description}</h3>
        {children}
      </Descriptions>
    </Container>
  );
}

export default ActionMenuHeader;
