import {CSSProperties, useEffect, useState} from 'react';
import {useReactFlow} from 'reactflow';

type IIconMode = {
  isIconMode: boolean;
  onToggleIconMode(bool?: boolean): void;
};

type IConstraint = {
  backupStyle: CSSProperties;
};

/**
 * widget 의 아이콘 모드를 구현하는 hook
 * 하는 일은 다음과 같음
 * 1. meta
 * @param id
 * @param metaData
 * @param width
 * @param height
 */
function useIconMode<T extends IConstraint>(id: string, metaData: T, width: number, height: number): IIconMode {
  const reactFlow = useReactFlow();
  const [isIconMode, setIsIconMode] = useState(false);

  const defaultIconModeStyle = {
    width: width,
    height: height,
    maxWidth: width,
    maxHeight: height,
    minWidth: width,
    minHeight: height
  };

  useEffect(() => {
    if (metaData) {
      setIsIconMode(Boolean(metaData.backupStyle));
    }
  }, [metaData]);

  const onToggleIconMode = (bool?: boolean): void => {
    const isBool = bool ?? !isIconMode;
    setIsIconMode(isBool);

    reactFlow.setNodes((nodes) =>
      nodes.map((item) => {
        if (item.id === id) {
          if (isBool) {
            // metaData 에 normal 상태의 현재 크기를 저장하여 복구할 때 사용 한다.
            metaData.backupStyle = item.style;
            // icon mode 상태의 크기로 지정 한다.
            item.style = defaultIconModeStyle;
          } else {
            // 백업해 두었던 스타일을 복귀 시키고
            item.style = metaData.backupStyle;
            // backupStyle 을 null 로 대입하여 File 저장 후 다음 로드시 useEffect 에서 Boolean 으로 평가한다.
            metaData.backupStyle = null;
          }
        }
        return item;
      })
    );
  };

  return {isIconMode, onToggleIconMode};
}

export default useIconMode;
