import React, {ReactElement} from 'react';
import styled, {keyframes} from 'styled-components';
import GroupDivider from 'components/forms/GroupDivider';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsSpin} from '@fortawesome/pro-light-svg-icons';
import {IModelRunnerLoader} from 'components/pc/widgets/modelRunner/useModelRunnerLoader';
import LoadingPoints from 'components/common/LoadingPoint';

const Container = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 48px;
  background-color: #f9f9f9;
  border-top: 1px solid #766b6b;
  position: absolute;
  bottom: 0;
  z-index: 21;
  padding: 0 16px;
`;

const SeqStatus = styled.div`
  display: flex;
  width: 230px;
  align-items: center;

  //flex-direction: column;
`;

// 회전 애니메이션 정의
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StatusIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  svg {
    opacity: 30%;
    animation: ${spin} 2s linear infinite;
  }

  &.idle {
    color: ${({theme}) => theme.color.dark};
  }

  /*  &.wait_import,
  &.run_model,
  &.update_model,
  &.export_data,
  &.save_model,*/
  &.running,
  &.completed {
    svg {
      color: ${({theme}) => theme.color.running};
      opacity: 100%;
      //animation: ${spin} 2s linear infinite;
    }
  }
`;

const ServerStatus = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  //margin-left: 16px;
`;

const blink = keyframes`
    0%, 30%, 100% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
`;

const ModelRunnerHealth = styled.div`
  display: flex;
  align-items: center;
`;
const HealthCheck = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.color.healthCheck};
  animation: ${blink} 1.5s infinite;
  margin-right: 8px;
`;

const Cpu = styled.div``;

const Memory = styled.div``;

type IProps = {
  loader: IModelRunnerLoader;
  cpu: number;
  memory: number;
};

function ModelRunnerStatusBar({loader, cpu, memory}: IProps): ReactElement {
  const modelStatus = loader.status(loader.seqInfo, 'key');

  const modelStatusValue = loader.status(loader.seqInfo, 'value');

  const isLoading =
    modelStatus.sts === 'Idle' ||
    modelStatus.cmd === 'RUN_MODEL' ||
    modelStatus.cmd === 'UPDATE_MODEL' ||
    modelStatus.cmd === 'WAIT_MODELRUN' ||
    modelStatus.cmd === 'UPDATE_MODELINPUT' ||
    modelStatus.cmd === 'EXPORT_DATA' ||
    modelStatus.cmd === 'SAVE_MODEL';

  return (
    <Container>
      <SeqStatus>
        <StatusIcon
          className={classNames((modelStatus?.sts.toLowerCase() || modelStatus?.cmd.toLowerCase()) ?? undefined)}
        >
          {(modelStatus.sts !== '' || modelStatus.cmd !== '') && <FontAwesomeIcon icon={faArrowsSpin} size="xl" />}
        </StatusIcon>
        {modelStatusValue.sts || modelStatusValue.cmd} {isLoading && <LoadingPoints isLoading={isLoading} />}
      </SeqStatus>
      {/*<GroupDivider />*/}
      {/*<ServerStatus>
        {statusClassName !== '' && (
          <>
            <ModelRunnerHealth>
              <HealthCheck /> Model Runner
            </ModelRunnerHealth>
            <Cpu>CPU {cpu}mb</Cpu>
            <Memory>Memory {memory}mb</Memory>
          </>
        )}
      </ServerStatus>*/}
    </Container>
  );
}

export default ModelRunnerStatusBar;
