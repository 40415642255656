import {ReactElement} from 'react';
import styled from 'styled-components';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'components/forms';
import {ICommodityBasic} from 'components/pc/widgets/commodity/types';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import {faGripLines} from '@fortawesome/pro-light-svg-icons';

const Container = styled.div`
  display: flex;
  max-height: 200px;
  min-height: 200px;
  flex: 1;
  border: 1px solid #171523;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  height: 30px;
  width: 100%;
  min-height: 30px;
  max-height: 30px;
  background-color: #5556a9;
  color: #b0b1ea;
  white-space: nowrap;
  top: -2px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;

const ListBody = styled.div`
  height: 88%;
  overflow: auto;
  width: 100%;

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const ListItem = styled.div`
  .tags-icon {
    margin-right: 6px;
  }
`;

const getRenderItem =
  (items: ICommodityBasic[]) =>
  (provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => (
    <div
      className="draggable"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{...provided.draggableProps.style}}
    >
      <Button variant="none" style={{cursor: 'ns-resize'}}>
        <FontAwesomeIcon icon={faGripLines} color="#9496a6" />
      </Button>
      {items[rubric.source.index].tag[1]}
    </div>
  );

type IProps = {
  selectedItemList: ICommodityBasic[];
  onSort(list: ICommodityBasic[]): void;
};

function CommoditySelectItemList({selectedItemList, onSort}: IProps): ReactElement {
  const renderItem = getRenderItem(selectedItemList);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(selectedItemList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    onSort(items);
  };

  const align = selectedItemList.length === 0 ? 'center' : 'default';

  return (
    <Container>
      <Header>Selected Nodes</Header>
      <ListBody className={classNames(align, 'thin-scrollbar')}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" renderClone={renderItem}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {selectedItemList?.map((item, index) => (
                  <ListItem key={item.tag[1]}>
                    <Draggable draggableId={item.tag[1]} index={index}>
                      {renderItem}
                    </Draggable>
                  </ListItem>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {selectedItemList.length === 0 && (
          <ListItem>
            <FontAwesomeIcon className="tags-icon" icon={faTags} color="#B6B4C0" size="xl" />
            No selected nodes
          </ListItem>
        )}
      </ListBody>
    </Container>
  );
}

export default CommoditySelectItemList;
