import {MouseEvent, PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div<{$hasCursor: boolean}>`
  background-color: rgba(227, 228, 236, 0.5);
  border: 1px solid #a5a7b9;
  margin: auto;
  color: #9496a6;
  cursor: ${({$hasCursor}) => ($hasCursor ? 'pointer' : 'default')};

  &.sm {
    line-height: 1em;
    padding: 8px 20px;
    font-size: 12px;
  }
`;

type IProps = PropsWithChildren & {
  size?: 'sm';
  margin?: string | number;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
};

function NoItemDisplay({children, size = 'sm', margin, onClick}: IProps): ReactElement {
  return (
    <Container className={size} style={{margin}} $hasCursor={Boolean(onClick)} onClick={onClick}>
      {children}
    </Container>
  );
}

export default NoItemDisplay;
