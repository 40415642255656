import {getApiHost} from 'api/function';
import {IReturnLoginAPI} from 'components/auth/login/login-api-functions';
import SHA256 from 'crypto-js/sha256';

export type RoleInfo = {
  name: string;
  description: string;
};

export type UserInfo = {
  username: string; // user id
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  groups: string[];
  is_active?: boolean;
};

export type UserInfoWithoutUsername = {
  // update_user API 호출용 type
  firstName: string;
  lastName: string;
  email: string;
  roles?: string[];
  groups?: string[];
  is_active?: boolean;
};

export type GroupInfo = {
  name: string;
  description: string;
  roles: string[];
};

type IManagementApiBase = {
  success: boolean;
  detail?: string;
};

type IReturnGetRole = IManagementApiBase & {
  data: {roles: RoleInfo[]};
};

type IReturnGetGroup = IManagementApiBase & {
  data: {groups: GroupInfo[]};
};

type IReturnGetUser = IManagementApiBase & {
  data: {user_list: UserInfo[]};
};

const API_HOST = getApiHost();
const AUTH_URL = API_HOST + '/auth';

const INITIAL_PASSWORD = '0000';

export const managementApi = {
  role: {
    getRoleList: async (token: string) => {
      return fetch(`${AUTH_URL}/get_role`, {headers: {Authorization: `Bearer ${token}`}}).then((res) => {
        const response = res.json() as unknown;
        return response as IReturnGetRole;
      });
    },
    createRole: async (token: string, name: string, description: string) => {
      return fetch(`${AUTH_URL}/create_role`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({name, description})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    deleteRole: async (token: string, name: string) => {
      return fetch(`${AUTH_URL}/delete_role/${name}`, {
        method: 'DELETE',
        headers: {Authorization: `Bearer ${token}`}
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    updateRole: async (token: string, name: string, description: string) => {
      return fetch(`${AUTH_URL}/update_role/${name}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({description, name})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    }
  },
  group: {
    getGroupList: async (token: string) => {
      return fetch(`${AUTH_URL}/get_group`, {
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IReturnGetGroup;
      });
    },
    createGroup: async (token: string, name: string, description: string, roles: string[]) => {
      return fetch(`${AUTH_URL}/create_group`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({name, description, roles})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    deleteGroup: async (token: string, name: string) => {
      return fetch(`${AUTH_URL}/delete_group/${name}`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`}
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    updateGroup: async (token: string, name: string, description: string, roles: string[]) => {
      return fetch(`${AUTH_URL}/update_group/${name}`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({name, description, roles})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    assignRoleToGroup: async (token: string, role_name: string, group_name: string) => {
      return fetch(`${AUTH_URL}/assign_role_to_group`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({role_name, group_name})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    removeRoleFromGroup: async (token: string, role_name: string, group_name: string) => {
      return fetch(`${AUTH_URL}/remove_role_from_group`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({role_name, group_name})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    }
  },
  user: {
    getUserInfo: async (token: string) => {
      return fetch(`${AUTH_URL}/get_user_info`, {
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`}
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IReturnGetUser;
      });
    },
    createUser: async (username: string, firstName: string, lastName: string, email: string) => {
      const payLoad = {
        lastName,
        firstName,
        username,
        password: SHA256(INITIAL_PASSWORD).toString(),
        email
      };
      return fetch(`${AUTH_URL}/register`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payLoad)
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IReturnLoginAPI;
      });
    },
    updateUser: async (token: string, username: string, userInfo: UserInfoWithoutUsername) => {
      return fetch(`${AUTH_URL}/update_user`, {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({username, userInfo})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    deleteUser: async (token: string, name: string) => {
      return fetch(`${AUTH_URL}/delete_user`, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({name})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    getUserList: async (token: string) => {
      return fetch(`${AUTH_URL}/get_user_list?include_inactive=true`, {
        method: 'GET',
        headers: {Authorization: `Bearer ${token}`}
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IReturnGetUser;
      });
    },
    assignGroup: async (token: string, group_name: string, username: string) => {
      return fetch(`${AUTH_URL}/assign_group`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({group_name, username})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    removeGroup: async (token: string, group_name: string, username: string) => {
      return fetch(`${AUTH_URL}/remove_group`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({group_name, username})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    assignRole: async (token: string, role_name: string, username: string) => {
      return fetch(`${AUTH_URL}/assign_role`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({role_name, username})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    removeRole: async (token: string, role_name: string, username: string) => {
      return fetch(`${AUTH_URL}/remove_role`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({role_name, username})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    activateUser: async (token: string, username: string) => {
      return fetch(`${AUTH_URL}/activate_user`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({username})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    },
    deactivateUser: async (token: string, username: string) => {
      return fetch(`${AUTH_URL}/deactivate_user`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
        body: JSON.stringify({username})
      }).then((res) => {
        const response = res.json() as unknown;
        return response as IManagementApiBase;
      });
    }
  }
};
