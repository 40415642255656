import React, {ReactElement} from 'react';
import styled from 'styled-components';
import {IModelRunnerProject} from 'components/pc/widgets/modelRunner/types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFolderOpen} from '@fortawesome/pro-light-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

const Container = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 16px;
  }
`;

type IProps = {
  isValidProject: boolean;
  project: IModelRunnerProject;
};

function ConnectedProjectMessage({isValidProject, project}: IProps): ReactElement {
  return (
    <Container>
      {isValidProject ? (
        <>
          <FontAwesomeIcon icon={faFolderOpen as IconProp} size="2x" />
          <p>Successfully opened {<strong>{project?.alias}</strong>} project.</p>
        </>
      ) : (
        <p>No projects available to open</p>
      )}
    </Container>
  );
}

export default ConnectedProjectMessage;
