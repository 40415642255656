import {BasicSpinner, ModalBody, ModalHeader, ModalWrapper} from 'components/common';
import styled from 'styled-components';
import {Dispatch, ReactElement, SetStateAction, useEffect, useState} from 'react';
import {Step, StepContainer, StepScroller} from 'components/common/stepper';
import CountdownTimer from 'components/common/CountdownTimer';

const ModalBodyMessage = styled.div`
  font-size: 17px;
  font-weight: 400;
  color: #525f7f;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
`;

export type ISaveStatusModalState = {
  title?: string;
  stepMessage1?: 'Saving current file';
  stepMessage2?: string;
};

type IProps = {
  modalState: ISaveStatusModalState;
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
};

function SaveLoadingModal({modalState, loadingState}: IProps): ReactElement {
  const [, setIsShowSaveLoadingModal] = loadingState;
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (modalState.stepMessage2) {
      setStep(1);
    }
  }, [modalState.stepMessage2]);

  return (
    <ModalWrapper type="sm">
      <ModalHeader>{modalState.title}</ModalHeader>
      <ModalBody align="center" size="full-width">
        <StepContainer width={560}>
          <StepScroller total={2} step={step}>
            <Step>
              <ElementContainer>
                <BasicSpinner isShow={true} size="md" type="inline" position="center-left" margin={2} delayTime={1} />
                <ModalBodyMessage>{modalState.stepMessage1}</ModalBodyMessage>
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <ModalBodyMessage>{modalState.stepMessage2}</ModalBodyMessage>
                <CountdownTimer
                  start={step === 1}
                  hasMessage={false}
                  total={1}
                  onComplete={() => setIsShowSaveLoadingModal(false)}
                />
              </ElementContainer>
            </Step>
          </StepScroller>
        </StepContainer>
      </ModalBody>
    </ModalWrapper>
  );
}

export default SaveLoadingModal;
