import {ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${({theme}) => theme.color.optional};
  font-size: 0.9em;
`;
const Message = styled.span``;
const Count = styled.span``;
const Seconds = styled.span``;

const defaultMsg = 'This dialog will close in';

type IProps = {
  start: boolean;
  total: number;
  hasMessage?: boolean;
  message?: string;
  onComplete?(): void;
};

/**
 * Countdown 후 onComplete 을 실행
 * <CountdownTimer start total={5} onComplete={onClose} />
 * 위와 같은 형태로 사용한다
 * @param start
 * @param total
 * @param hasMessage
 * @param msg
 * @param onComplete
 * @constructor
 */
function CountdownTimer({start, total, hasMessage = true, message = defaultMsg, onComplete}: IProps): ReactElement {
  const [remaining, setRemaining] = useState(total);

  useEffect(() => {
    if (!start || !total) return;

    let count = total + 1; // 5초 카운트다운이라면 실제로는 6초간 해야 5초부터 보여줄 수 있음
    const next = () => {
      count = count - 1;
      setRemaining(count);

      if (count > 0) {
        setTimeout(next, 1000);
      } else {
        onComplete?.();
      }
    };

    next();
  }, [start, total]);

  if (hasMessage) {
    return (
      <Container>
        <Message>{message}</Message>
        <Count>{remaining}</Count>
        <Seconds>second{remaining > 1 && 's'}</Seconds>
      </Container>
    );
  } else {
    return undefined;
  }
}

export default CountdownTimer;
